import storeUtil from '@commerceCommon/utils/storeUtil.js'
import { isEmpty } from '@commerceCommon/utils/common.js'

const TargetConfigMarket3PKey = 'TargetConfigMarket3P'
const TargetConfigMarket1PKey = 'TargetConfigMarket1P'
const TargetConfigStore3PKey = 'TargetConfigStore3P'
const TargetConfigStore1PKey = 'TargetConfigStore1P'
const TargetConfigCategory3PKey = 'TargetConfigCategory3P'
const TargetConfigCategory1PKey = 'TargetConfigCategory1P'
const TargetConfigBrand3PKey = 'TargetConfigBrand3P'
const TargetConfigBrand1PKey = 'TargetConfigBrand1P'
const TargetConfigTag3PKey = 'TargetConfigTag3P'
const TargetConfigTagCondition3PKey = 'TargetConfigTagCondition3P'
const TargetConfigTagOwner3PKey = 'TargetConfigTagOwner3P'
const TargetConfigTag1PKey = 'TargetConfigTag1P'
const TargetConfigTagCondition1PKey = 'TargetConfigTagCondition1P'
const TargetDateKey = 'TargetDate'

const state = () => ({
  // target单选配置
  countryCode_vendor: localStorage.getItem(TargetConfigMarket1PKey) || '',
  countryCode_seller: localStorage.getItem(TargetConfigMarket3PKey) || '',
  sellerId: JSON.parse(localStorage.getItem(TargetConfigStore3PKey) || '[]'),
  vendorGroupId: JSON.parse(localStorage.getItem(TargetConfigStore1PKey) || '[]'),
  category_vendor: JSON.parse(localStorage.getItem(TargetConfigCategory1PKey) || '[]'),
  category_seller: JSON.parse(localStorage.getItem(TargetConfigCategory3PKey) || '[]'),
  brand_vendor: JSON.parse(localStorage.getItem(TargetConfigBrand1PKey) || '[]'),
  brand_seller: JSON.parse(localStorage.getItem(TargetConfigBrand3PKey) || '[]'),
  tag_vendor: JSON.parse(localStorage.getItem(TargetConfigTag1PKey) || '[]'),
  tagCondition_vendor: JSON.parse(localStorage.getItem(TargetConfigTagCondition1PKey) || '0'),
  tagOwner_seller: JSON.parse(localStorage.getItem(TargetConfigTagOwner3PKey) || '[]'),
  tag_seller: JSON.parse(localStorage.getItem(TargetConfigTag3PKey) || '[]'),
  tagCondition_seller: JSON.parse(localStorage.getItem(TargetConfigTagCondition3PKey) || '0'),
  dateRange: JSON.parse(localStorage.getItem(TargetDateKey)) || storeUtil.dateRange.value
})

const actions = {
  // 设置vendor
  setVendorConfig(payload) {
    const { market, store, brand, category, tagIds, tagCondition } = payload
    this.countryCode_vendor = market
    this.vendorGroupId = store
    localStorage.setItem(TargetConfigMarket1PKey, market)
    localStorage.setItem(TargetConfigStore1PKey, JSON.stringify(store))
    if (brand) {
      this.brand_vendor = brand
      localStorage.setItem(TargetConfigBrand1PKey, JSON.stringify(brand))
    }
    if (category) {
      this.category_vendor = category
      localStorage.setItem(TargetConfigCategory1PKey, JSON.stringify(category))
    }
    if (tagIds) {
      this.tag_vendor = tagIds
      localStorage.setItem(TargetConfigTag1PKey, JSON.stringify(tagIds))
    }
    if (!isEmpty(tagCondition)) {
      this.tagCondition_vendor = tagCondition
      localStorage.setItem(TargetConfigTagCondition1PKey, JSON.stringify(tagCondition))
    }
  },
  // 设置seller
  setSellerConfig(payload) {
    const { market, store, brand, category, tagIds, tagOwner, tagCondition } = payload
    this.countryCode_seller = market
    this.sellerId = store
    localStorage.setItem(TargetConfigMarket3PKey, market)
    localStorage.setItem(TargetConfigStore3PKey, JSON.stringify(store))
    if (brand) {
      this.brand_seller = brand
      localStorage.setItem(TargetConfigBrand3PKey, JSON.stringify(brand))
    }
    if (category) {
      this.category_seller = category
      localStorage.setItem(TargetConfigCategory3PKey, JSON.stringify(category))
    }
    if (tagOwner) {
      this.tagOwner_seller = tagOwner
      localStorage.setItem(TargetConfigTagOwner3PKey, JSON.stringify(tagOwner))
    }
    if (tagIds) {
      this.tag_seller = tagIds
      localStorage.setItem(TargetConfigTag3PKey, JSON.stringify(tagIds))
    }
    if (!isEmpty(tagCondition)) {
      this.tagCondition_vendor = tagCondition
      localStorage.setItem(TargetConfigTagCondition3PKey, JSON.stringify(tagCondition))
    }
  },
  SET_DATERANGE(dateRange) {
    localStorage.setItem(TargetDateKey, JSON.stringify(dateRange))
    this.dateRange = dateRange
  }
}
export default {
  state,
  actions
}

