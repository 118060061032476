import Main from '@pacvue/frame/layout/Main.vue'
export default {
  path: '/Alert',
  component: Main,
  meta: {
    auth: true,
    id: 2
  },
  sort: 300,
  redirect: '/Alert/Alerts',
  children: [
    {
      path: '/Alert/Alerts',
      component: () => import('@!/views/Alert/Alerts/index.vue'),
      name: 'AlertsMain',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm103'
      }
    },
    {
      path: '/Alert/AlertHistory',
      component: () => import('@!/views/Alert/ProductDetails/index.vue'),
      meta: {
        autoTestPrefix: 'auto_bm104'
      },
      name: 'AlertHistory'
    },
    {
      path: '/Alert/AlertCaseHistory',
      component: () => import('@!/views/Alert/CaseHistory/index.vue'),
      meta: {
        autoTestPrefix: 'auto_bm104'
      },
      name: 'CaseHistory'
    }
  ]
}
