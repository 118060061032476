import request from "@pacvue/frame/request"
const { VITE_APP_CustomDashbord, VITE_APP_SOV2 } = import.meta.env
import { VITE_APP_WALMART_BASE_URL } from "@customDashboard/api/appAPI.js"
const productline = localStorage.getItem("productline")
const ChartLibraryProductLine = localStorage.getItem("ChartLibraryProductLine") || "amazon"
export function getCampaignOption(data = {}) {
  const option = [
    {
      tagName: "Campaign1",
      tagId: "Campaign1"
    },
    {
      tagName: "Campaign2",
      tagId: "Campaign2"
    },
    {
      tagName: "Campaign3",
      tagId: "Campaign3"
    },
    {
      tagName: "Campaign4",
      tagId: "Campaign4"
    }
  ]
  return option
}
export function getCampaignTagOption(data = {}) {
  const option = [
    {
      tagName: "CampaignTag1",
      tagId: "CampaignTag1"
    },
    {
      tagName: "CampaignTag2",
      tagId: "CampaignTag2"
    },
    {
      tagName: "CampaignTag3",
      tagId: "CampaignTag3"
    },
    {
      tagName: "CampaignTag4",
      tagId: "CampaignTag4"
    }
  ]
  return option
}
export function getTagOption(data = {}) {
  const option = [
    {
      tagName: "Tag1",
      tagId: "CTag1"
    },
    {
      tagName: "Tag2",
      tagId: "Tag2"
    },
    {
      tagName: "Tag3",
      tagId: "Tag3"
    },
    {
      tagName: "Tag4",
      tagId: "Tag4"
    }
  ]
  return option
}
// 接口
export function getCampaignList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCampaignList`,
    method: "post",
    data: data
  })
}
export function getCampaignTagList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCampaignTagList`,
    method: "post",
    data: data
  })
}
export function getCampaignParentTagList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCampaignParentTagList`,
    method: "post",
    data: data
  })
}
export function getASINList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getASINList`,
    method: "post",
    data: data
  })
}
export function getASINTagList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getASINTagList`,
    method: "post",
    data: data
  })
}
export function getASINParentTagList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getASINParentTagList`,
    method: "post",
    data: data
  })
}
export function getKeywordList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getKeywordList`,
    method: "post",
    data: data
  })
}
export function getKeywordTagList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getKeywordTagList`,
    method: "post",
    data: data
  })
}
export function getKeywordParentTagList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getKeywordParentTagList`,
    method: "post",
    data: data
  })
}
export function getSovGroupWithMarket(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getSovGroupWithMarket`,
    method: "post",
    data: data
  })
}
export function getCommerceBrand(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceBrand`,
    method: "post",
    data: data
  })
}
export function getCommerceVendorGroup(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceVendorGroup`,
    method: "post",
    data: data
  })
}
export function getCommerceAsin(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceAsin`,
    method: "post",
    data: data
  })
}

export function getProfileList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getProfileList`,
    method: "post",
    data: data
  })
}
export function getShareProfileList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}share/data/getProfileList`,
    method: "post",
    data: data
  })
}
export function getCommerceProfile(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceProfile`,
    method: "post",
    data: data
  })
}
export function getSovBrand(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getSovBrand`,
    method: "post",
    data: data
  })
}
export function getSovAsin(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getSovAsin`,
    method: "post",
    data: data
  })
}
export function getSearchTermList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getSearchTerm`,
    method: "post",
    data: data
  })
}
export function getItemList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getItemList`,
    method: "post",
    data: data
  })
}
export function getItemParentTagList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getItemParentTagList`,
    method: "post",
    data: data
  })
}
export function getItemTagList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getItemTagList`,
    method: "post",
    data: data
  })
}
export function getProductTagList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getProductTagList`,
    method: "post",
    data: data
  })
}
export function getProductList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getProductList`,
    method: "post",
    data: data
  })
}
export function getProductParentTagList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getProductParentTagList`,
    method: "post",
    data: data
  })
}
export function getcustomDashboardList() {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/list`,
    method: "get"
  })
}

export function getcustomDashboardById(id) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/getById/${id}`,
    method: "get",
    isIgnoreRequestRegister: true
  })
}
export function getChartById(id) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/getChartById/${id}`,
    method: "get"
  })
}
export function getChartData(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/queryChart`,
    method: "post",
    data: data,
    isIgnoreRequestRegister: true
  })
}
export function saveLineChart(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/saveLineChart`,
    method: "post",
    data: data
  })
}
export function saveLineChartTemplate(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/saveLineChartTemplate`,
    method: "post",
    headers: {
      productline: productline === "adminsuite" ? ChartLibraryProductLine : null
    },
    data: data
  })
}
export function saveBarChart(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/saveBarChart`,
    method: "post",
    data: data
  })
}
export function saveBarChartTemplate(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/saveBarChartTemplate`,
    method: "post",
    headers: {
      productline: productline === "adminsuite" ? ChartLibraryProductLine : null
    },
    data: data
  })
}
export function savePieChart(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/savePieChart`,
    method: "post",
    data: data
  })
}
export function savePieChartTemplate(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/savePieChartTemplate`,
    method: "post",
    headers: {
      productline: productline === "adminsuite" ? ChartLibraryProductLine : null
    },
    data: data
  })
}
export function saveStackedBarChart(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/saveStackedBarChart`,
    method: "post",
    data: data
  })
}
export function saveStackedBarChartTemplate(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/saveStackedBarChartTemplate`,
    method: "post",
    headers: {
      productline: productline === "adminsuite" ? ChartLibraryProductLine : null
    },
    data: data
  })
}
export function saveDoshboard(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/save`,
    method: "post",
    data: data
  })
}
export function deleteById(id) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/deleteById/${id}`,
    method: "delete"
  })
}
export function deleteChartById(id) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/deleteChartById/${id}`,
    method: "delete"
  })
}
export function copyChartById(id, name) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/copy/${id}?dashboardName=${name}`,
    method: "get"
  })
}
export function viewExample() {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/viewExample`,
    method: "get"
  })
}

export function saveWhiteBoard(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/saveWhiteBoard`,
    method: "post",
    data: data
  })
}
export function saveTopOverView(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/saveTopOverview`,
    method: "post",
    data: data
  })
}
export function saveTopOverviewTemplate(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/saveTopOverviewTemplate`,
    method: "post",
    data: data,
    headers: {
      productline: productline === "adminsuite" ? ChartLibraryProductLine : null
    }
  })
}
export function saveTable(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/saveTable`,
    method: "post",
    data: data
  })
}
export function saveTableTemplate(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/saveTableTemplate`,
    method: "post",
    headers: {
      productline: productline === "adminsuite" ? ChartLibraryProductLine : null
    },
    data: data
  })
}
export function getTips(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/tips`,
    method: "post",
    data: data
  })
}
export function briefTips(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/briefTips`,
    method: "post",
    data: data
  })
}
export function sharelink_briefTips(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}share/briefTips`,
    method: "post",
    data: data
  })
}
export function getCommerceUserType(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceUserType`,
    method: "post",
    data: data
  })
}
// share link
export function createShareLink(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}share/create`,
    method: "post",
    data
  })
}
export function getDashboard(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}share/getDashboard`,
    method: "post",
    data
  })
}
export function getQueryChart(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}share/queryChart`,
    method: "post",
    data,
    isIgnoreRequestRegister: true
  })
}
export function getDspASIN(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDspAsin`,
    method: "post",
    data: data
  })
}
export function getCreative(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDspCreative`,
    method: "post",
    data: data
  })
}
export function getCreativeTag(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDspCreativeTag`,
    method: "post",
    data: data
  })
}
export function getCreativeParentTag(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDspCreativeParentTag`,
    method: "post",
    data: data
  })
}
export function getDspLineItemTag(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDspLineItemTag`,
    method: "post",
    data: data
  })
}
export function getLineItemTag(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getLineItemTag`,
    method: "post",
    data: data
  })
}
export function getDspLineItemParentTag(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDspLineItemParentTag`,
    method: "post",
    data: data
  })
}
export function getLineItemParentTag(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getLineItemParentTag`,
    method: "post",
    data: data
  })
}
export function getDspLineItem(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDspLineItem`,
    method: "post",
    data: data
  })
}
export function getLineItem(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getLineItem`,
    method: "post",
    data: data
  })
}
export function getOrderParentTag(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDspOrderParentTag`,
    method: "post",
    data: data
  })
}
export function getOrderTag(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDspOrderTag`,
    method: "post",
    data: data
  })
}
export function getOrder(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDspOrder`,
    method: "post",
    data: data
  })
}

export function getAdvertiser(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDspAdvertiserList`,
    method: "post",
    data: data
  })
}
export function getEntity(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDspProfileList`,
    method: "post",
    data: data
  })
}
export function GetClientMarket(data) {
  return request({
    url: `${VITE_APP_SOV2}SOV/GetClientMarket`,
    data,
    method: "get"
  })
}
export function getCampaignTypesList() {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCampaignTypes`,
    method: "get"
  })
}
export function getAdvertiserList(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getAdvertiserList`,
    method: "post",
    data: data
  })
}
// template接口
export function templateGroupList() {
  return request({
    url: `${VITE_APP_CustomDashbord}template/templateGroupList`,
    method: "get",
    headers: {
      productline: productline === "adminsuite" ? ChartLibraryProductLine : null
    }
  })
}
export function templateList(favorite, groupId, chartType, keyword = "") {
  return request({
    url: `${VITE_APP_CustomDashbord}template/templateList?favorite=${favorite}&groupId=${groupId}&chartType=${chartType}&keyword=${keyword}`,
    method: "get"
  })
}
//该接口判断template下面有没有模板
export function checkTemplateGroup(templateGroupId) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/checkTemplateGroup?templateGroupId=${templateGroupId}`,
    method: "get"
  })
}
export function deleteTemplate(templateIdList) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/deleteTemplate?templateIdList=${templateIdList}`,
    method: "get"
  })
}
export function deleteTemplateGroup(toDeletedGroupId, toMovedGroupId) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/deleteTemplateGroup?toDeletedGroupId=${toDeletedGroupId}&toMovedGroupId=${toMovedGroupId}`,
    method: "get"
  })
}
export function saveTemplateGroup(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/saveTemplateGroup`,
    method: "post",
    data: data
  })
}
export function collect(templateId) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/collect?templateId=${templateId}`,
    method: "get"
  })
}
export function cancelCollect(templateId) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/cancelCollect?templateId=${templateId}`,
    method: "get"
  })
}
export function moveGroup(templateGroupId, templateIdList) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/moveGroup?templateGroupId=${templateGroupId}&templateIdList=${templateIdList}`,
    method: "get"
  })
}
export function copy(templateId, name, groupId) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/copy?templateId=${templateId}&name=${name}&groupId=${groupId}`,
    method: "get"
  })
}
export function templateLabelList() {
  return request({
    url: `${VITE_APP_CustomDashbord}template/templateLabelList`,
    method: "get",
    headers: {
      productline: productline === "adminsuite" ? ChartLibraryProductLine : null
    }
  })
}
export function getDefaultCurrency(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getDefaultCurrency`,
    method: "post",
    data: data
  })
}
export function applyTemplates(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}template/applyTemplates`,
    method: "post",
    data: data
  })
}
export function getcustomDashboardSummary(params) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/summary`,
    method: "get",
    params
  })
}
export function getCommerceVendorASIN(data = null) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceVendorAsin`,
    method: "post",
    data: {
      productLine: productline,
      asin: data?.keyword || null,
      countryCodes: data?.countryCodes || [],
      is3p: data?.is3p || false
    },
    isIgnoreRequestRegister: true
  })
}
export function getCommerceMarket(obj) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceVendorMarket`,
    method: "post",
    data: { productLine: productline, ...obj },

    isIgnoreRequestRegister: true
  })
}
export function getCommerceAccount(obj) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceVendorGroup`,
    method: "post",
    data: { productLine: productline, ...obj },
    isIgnoreRequestRegister: true
  })
}
export function getCommerceVendorCategory(obj) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceVendorCategory`,
    method: "post",
    data: { productLine: productline, ...obj },
    isIgnoreRequestRegister: true
  })
}
export function getCommerceAmazonCategory(obj) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceVendorCategoryForAdvertising`,
    method: "post",
    data: { productLine: productline, ...obj },
    isIgnoreRequestRegister: true
  })
}
export function getCommerceVendorBrand(obj) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceVendorBrand`,
    method: "post",
    data: { productLine: productline, ...obj },
    isIgnoreRequestRegister: true
  })
}
export function getCommerceAmazonBrand(obj) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceVendorBrandForAdvertising`,
    method: "post",
    data: { productLine: productline, ...obj },
    isIgnoreRequestRegister: true
  })
}
export function getCommerceProductTag(obj) {
  return request({
    url: `${VITE_APP_CustomDashbord}data/getCommerceVendorProductTag`,
    method: "post",
    data: { productLine: productline, ...obj },
    isIgnoreRequestRegister: true
  })
}
export function getUserGuidanceStep() {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}User/Guidance`,
    method: "GET",
    headers: {
      productline: "walmart"
    }
  })
}
export function getShareLinkUserGuidanceStep(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}share/walmartUserGuidance`,
    method: "post",
    data: data
  })
}
export function getChartDisplayValues(params) {
  return request({
    url: `${VITE_APP_CustomDashbord}customDashboard/chartDisplayValues?chartId=${params.chartId}`,
    method: "get"
  })
}
export function getShareLinkChartDisplayValues(data) {
  return request({
    url: `${VITE_APP_CustomDashbord}share/chartDisplayValues`,
    method: "post",
    data: data
  })
}
