import Main from '@pacvue/frame/layout/Main.vue'

const demoRoutes = {
  path: '/demo',
  component: Main,
  meta: {
    auth: false,
    id: 3
  },
  sort: 0,
  redirect: '/Demo/CancelRequest',
  children: [
    // 重复请求取消
    {
      path: '/Demo/CancelRequest',
      component: () => import('@!/views/Demo/CancelRequest/index.vue'),
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        ignorePermissionProxy: true
      }
    },
    {
      path: '/Demo/Chart',
      component: () => import('@!/views/Demo/Chart/index.vue'),
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        ignorePermissionProxy: true
      }
    },
    {
      path: '/Demo/ExpandRowAutoResize',
      component: () => import('@!/views/Demo/ExpandRowAutoResize/index.vue'),
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        ignorePermissionProxy: true
      }
    },
    {
      path: '/Demo/TestDeep',
      component: () => import('@!/views/Demo/StorePersist/index.vue'), // 该页面为测试深度监听
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        ignorePermissionProxy: true,
        storeName: 'Home'
      }
    }
  ]
}

export default (function() {
  if (process.env.NODE_ENV !== 'development') {
    return []
  }
  return demoRoutes
})()
