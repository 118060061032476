
const state = () => ({
  personalCacheBy1P: {
    currentTab: '',
    ParentASINTabQueryModel: {},
    ASINTabQueryModel: {},
    CoOpDeductionsTabQueryModel: {}
  },
  personalCacheBy3P: {
    currentTab: '',
    SellerTabQueryModel: {},
    ParentASINTabQueryModel: {},
    ASINTabQueryModel: {},
    MSKUTabQueryModel: {},
    versionType: ''
  }
})
const actions = {

}
export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    watchFields: ['personalCacheBy1P', 'personalCacheBy3P'],
    isLogoutClearStorage: false
  }
}
