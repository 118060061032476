import Main from '@pacvue/frame/layout/Main.vue'
import { isEmpty } from '@commerceCommon/utils/common.js'
import { useCommonStore } from '@!/store/index.js'

export default {
  path: '/DigitalShelf',
  component: Main,
  meta: {
    auth: true,
    id: 2
  },
  sort: 500,
  redirect: '/DigitalShelf/BuyboxTracker',
  children: [
    {
      path: '/DigitalShelf/ProductAudit',
      component: () => import('@!/views/DigitalShelf/ProductAudit/index.vue'),
      name: 'ProductAudit',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm129'
      }
      /* beforeEnter(to, from, next) {
        const commonStore = useCommonStore()
        if (!isEmpty(to.query)) {
          commonStore.setCustomBrandCategoryValueMap({ brand: [], category: [] })
          commonStore.setOriginBrandCategoryValueMap({ brand: [], category: [] })
        }
        next()
      }*/
    },
    {
      path: '/DigitalShelf/ProductAuditDetail',
      component: () => import('@!/views/DigitalShelf/ProductAudit/Detail/index.vue'),
      name: 'ProductAuditDetail',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm170'
      }
    },
    {
      path: '/DigitalShelf/BuyboxTracker',
      component: () => import('@!/views/DigitalShelf/BuyboxTracker/index.vue'),
      name: 'BuyboxTracker',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm131'
      }
    },
    {
      path: '/DigitalShelf/BuyboxTracker/detail/buyboxTracker',
      component: () => import('@!/views/DigitalShelf/BuyboxTrackerDetail/index.vue'),
      name: 'BuyboxTrackerDetail',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm171'
        // relationPermissionPaths: [
        //   '/DigitalShelf/BuyboxTracker'
        // ]
      }
    },
    {
      path: '/DigitalShelf/Reviews',
      component: () => import('@!/views/DigitalShelf/Reviews/index.vue'),
      name: 'Reviews',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm130'
      }
    },
    {
      path: '/DigitalShelf/Reviews/ManageReviewsTag',
      component: () => import('@!/views/DigitalShelf/Reviews/components/ManageReviewsTag.vue'),
      name: 'ManageReviewsTag',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm172'
      }
    },
    {
      path: '/DigitalShelf/BrandAudit',
      component: () => import('@!/views/DigitalShelf/BrandAudit/index.vue'),
      name: 'BrandAudit',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm128'
      }
    },
    {
      path: '/DigitalShelf/Repricer',
      component: () => import('@!/views/DigitalShelf/Repricer/index.vue'),
      name: 'Repricer',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm173'
      }
    },
    {
      path: '/DigitalShelf/CustomStandard',
      component: () => import('@!/views/DigitalShelf/CustomStandard/index.vue'),
      name: 'CustomStandard',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm175'
      }
    }
  ]
}
