
const state = () => ({
  panelPlanListBy3p: [],
  panelPlanListBy1p: [],
  filterCache1p: {},
  filterCache3p: {},
  chartCache: {},
  panelCache1p: {},
  panelCache3p: {}
})

const actions = {}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isLogoutClearStorage: false,
    isModuleStorage: true,
    watchFields: ['panelPlanListBy3p', 'panelPlanListBy1p', 'filterCache3p', 'filterCache1p', 'chartCache', 'panelCache1p', 'panelCache3p']
  }
}
