import Main from '@pacvue/frame/layout/Main.vue'

export default {
  path: '/Business',
  component: Main,
  meta: {
    auth: true,
    id: 2
  },
  sort: 600,
  redirect: '/Business/Sales',
  children: [
    {
      path: '/Business/Sales',
      component: () => import('@!/views/Business/Sales/Sales/index.vue'),
      name: 'Sales',
      meta: {
        isRealTime: false,
        isShowTime: true,
        showCompareCheck: true,
        autoTestPrefix: 'auto_bm109'
      }
    },
    {
      path: '/Business/RealTimeSales',
      component: () => import('@!/views/Business/Sales/RealTime/index.vue'),
      name: 'RealTimeSales',
      meta: {
        isRealTime: true,
        isShowTime: true,
        showCompareCheck: true,
        storeName: 'realTimeSales'
      }
    },
    {
      path: '/Business/Sales/AsinTime',
      component: () => import('@!/views/Business/Sales/AsinTime/index.vue'),
      name: 'AsinTime',
      meta: {
        isRealTime: false,
        isShowTime: true,
        showCompareCheck: true,
        storeName: 'salesAsinTime'
      }
    },
    {
      path: '/Business/Sales/Target',
      component: () => import('@!/views/Business/Sales/Target/index.vue'),
      name: 'SalesTarget',
      meta: {
        isRealTime: false,
        isShowTime: false,
        showCompareCheck: false,
        storeName: 'salesTarget'
      }
    },
    {
      path: '/Business/RealTime/AsinTime',
      component: () => import('@!/views/Business/Sales/AsinTime/index.vue'),
      name: 'RealTimeAsinTime',
      meta: {
        isRealTime: true,
        isShowTime: true,
        showCompareCheck: true,
        storeName: 'salesAsinTime'
      }
    },
    {
      path: '/Business/RealTime/Target',
      component: () => import('@!/views/Business/Sales/RealTimeTarget/index.vue'),
      name: 'RealTimeTarget',
      meta: {
        isRealTime: true,
        isShowTime: true,
        showCompareCheck: true,
        storeName: 'salesTarget'
      }
    },
    {
      path: '/Business/Order',
      component: () => import('@!/views/Business/Order/index.vue'),
      name: 'Order',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm113'
      }
    },
    {
      path: '/Business/Inventory',
      name: 'Inventory',
      component: () => import('@!/views/Inventory/Inventory/index.vue'),
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm115'
      }
    },
    {
      path: '/Business/Profitability',
      component: () => import('@!/views/Profitability/Profitability/index.vue'),
      name: 'Profitability',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm118'
      }
    },
    {
      path: '/Business/Profitability/UploadCost',
      component: () => import('@!/views/Profitability/Profitability/components/UploadCost.vue'),
      name: 'Upload Cost',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm119'
      }
    },
    {
      path: '/Business/Profitability/UploadCostLog',
      component: () => import('@!/views/Profitability/Profitability/components/UploadCostLog.vue'),
      name: 'Upload Cost Log',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm120'
      }
    },
    {
      path: '/Business/Profitability/ClientView',
      component: () => import('@!/views/Profitability/ClientView/index.vue'),
      name: 'Client View',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm121'
      }
    },
    {
      path: '/Business/ConsumptionForecast',
      component: () => import('@!/views/Business/Forecast/index.vue'),
      name: 'ConsumptionForecast',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm116'
      }
    },
    {
      path: '/Business/POForecast',
      component: () => import('@!/views/Business/POForecast/index.vue'),
      name: 'POForecast',
      meta: {
        isShowTime: false,
        showCompareCheck: false
      }
    },
    {
      path: '/Business/Coupon',
      component: () => import('@!/views/Business/Coupon/index.vue'),
      name: 'Coupon',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm111'
      }
    },
    {
      path: '/Business/Promotion',
      component: () => import('@!/views/Business/Promotion/index.vue'),
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm110',
        storeName: 'promotion'
      }
    },
    {
      name: 'PromotionDetail',
      path: '/Business/Promotion/detail/promotion',
      component: () => import('@!/views/Business/PromotionDetails/index.vue'),
      meta: {
        isShowTime: true,
        showCompareCheck: false
      }
    },
    {
      name: 'PromotionAnalysisByPromotion',
      path: '/Business/PromotionAnalysis/ByPromotion',
      component: () => import('@!/views/Business/PromotionAnalysis/ByPromotion.vue'),
      meta: {
        isShowTime: true,
        showCompareCheck: false
      }
    },
    {
      name: 'PromotionAnalysisByProduct',
      path: '/Business/PromotionAnalysis/ByProduct',
      component: () => import('@!/views/Business/PromotionAnalysis/ByProduct.vue'),
      meta: {
        isShowTime: true,
        showCompareCheck: false
      }
    },
    {
      path: '/Business/Inventory/detail',
      component: () => import('@!/views/Inventory/Inventory/ManageWOCFormulaTemplate/index.vue'),
      name: 'FormulaTemplate',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm168'
      }
    }
  ]
}
