// @vitest-environment jsdom
import type { Chart } from 'highcharts'
import Highcharts from 'highcharts'
import common from '@pacvue/utils'
import type { Ref } from 'vue'
import { customRef } from 'vue'
import { debounce, cloneDeep } from 'lodash-es'

export const isASIN = (asin: string): boolean => {
  if (Object.prototype.toString.call(asin) !== '[object String]') { return false }
  if (asin === '') { return false }
  return /^([A-Za-z0-9]{10})$/g.test(asin)
}

// vitest in-source testing
if (import.meta.vitest) {
  // test isASIN
  const { describe, expect, it, test } = import.meta.vitest
  describe('isASIN', async() => {
    test('Valid ASINs', () => {
      const validAsinArray = ['B07NVT9B7Z', 'US12345678', 'b07nvt9b71']
      expect(validAsinArray.map(isASIN).every(result => result === true)).toBe(true)
    })
    test('Invalid ASINs', () => {
      const invalidAsinArray = ['B07NVT9B7', 'B07NVT9B7ZZ', 'B07NVT#B7Z', 'asd']
      expect(invalidAsinArray.map(isASIN).every(result => result === false)).toBe(true)
    })
  })
}

// 判断是否为空值
export const isVoid = (param:any):boolean => {
  return param === undefined || param === null
}

// vitest in-source testing
if (import.meta.vitest) {
  // test isVoid
  const { describe, expect, it, test } = import.meta.vitest
  describe('isVoid', async() => {
    test('Valid voids', () => {
      expect(isVoid(undefined)).toBe(true)
      expect(isVoid(null)).toBe(true)
    })
    test('Invalid voids', () => {
      expect(isVoid('')).toBe(false)
      expect(isVoid(0)).toBe(false)
      expect(isVoid(false)).toBe(false)
      expect(isVoid({})).toBe(false)
      expect(isVoid([])).toBe(false)
    })
  })
}

// 获取 lower str
export const getLowerStr = (str:string):string => {
  return str.toLowerCase()
}

// vitest in-source testing
if (import.meta.vitest) {
  // test getLowerStr
  const { describe, expect, it, test } = import.meta.vitest
  describe('getLowerStr', async() => {
    test('Valid lower str', () => {
      expect(getLowerStr('HELLO')).toBe('hello')
    })
    test('Invalid lower str', () => {
      expect(getLowerStr('hello')).toBe('hello')
    })
  })
}

// 根据字体样式 用canvas计算宽度
export const getFontWidthByCanvas = (fontVal:string | number, fontStyle:string):number => {
  let canvas = document.createElement('canvas')
  let context = canvas.getContext('2d')
  context.font = fontStyle
  const textWidth = context.measureText(fontVal.toString()).width
  canvas = null
  context = null
  return Math.ceil(textWidth)
}

// vitest jsdom 不能获取正确的canvas上下文对象

/**
 * @description: router中包裹在 components: 配置项加载的组件上，可在组件卸载前自动卸载当前 Highcharts 上挂载的且未卸载的Chart图实例
 * @param {function} compImport
 * @return {*}
 */
export const destroyChart = (compImport: () => Promise<{
  default: {
    beforeUnmount?: () => void
  }
}>) => {
  return new Promise(resolve => {
    compImport().then(res => {
      const oldUnmount = res.default.beforeUnmount
      const newUnmount = () => {
        oldUnmount?.call(res.default)
        const destroys: [Chart, () => void][] = Highcharts.charts
          .filter(item => !!item)
          .filter(item => Object.keys(item).length)
          .map(item => [item, item.destroy])
        const preparedToDestroyChartLength = destroys.length
        while (destroys.length) {
          const [chart, destroyFn] = destroys.pop()
          try {
            destroyFn.call(chart)
            // 以防在组件中已经写过卸载事件了，改成了空函数，让组件自己写的destroy变成执行空函数
            chart.destroy = () => {}
          } catch (error) {
            console.error(error, chart)
          }
        }
        const notDestroyedLength = Highcharts.charts
          .filter(item => !!item)
          .filter(item => Object.keys(item).length).length
        // console.log(`卸载了${preparedToDestroyChartLength}个Chart，未卸载的Chart数: ${notDestroyedLength}个`)
      }
      res.default.beforeUnmount = newUnmount
      resolve(res)
    })
  })
}

// vitest Highcharts.chart初始化的mock对象destroy后不会删除Chart本身, 会导致测试用例失败

// 获取视图文本
export const getViewText = (text:string | number | void, emptyText = '--'):string | number => {
  if (isVoid(text) || text === '') {
    return emptyText
  }
  return text as (string | number)
}

// vitest in-source testing
if (import.meta.vitest) {
  // test getViewText
  const { describe, expect, it, test } = import.meta.vitest
  describe('getViewText', async() => {
    test('Valid view text', () => {
      expect(getViewText('HELLO')).toBe('HELLO')
      expect(getViewText(0)).toBe(0)
    })
    test('Invalid view text', () => {
      expect(getViewText(undefined)).toBe('--')
      expect(getViewText(null)).toBe('--')
      expect(getViewText('')).toBe('--')
    })
  })
}

// 转换枚举列表为对象形式
interface EnumItem { [ prop: string ]: any }

export const getEnmuMapByListKey = < T extends EnumItem, L extends Array<T>, K extends keyof T > (enumList:L, key: K): { [prop:string ]: T } => {
  return Object.fromEntries(
    enumList.map(item => {
      return [item[ key ], { ...item }]
    })
  )
}

export const getEnumMapByListKey = getEnmuMapByListKey

// vitest in-source testing
if (import.meta.vitest) {
  // test getEnmuMapByListKey
  const { describe, expect, it, test } = import.meta.vitest
  describe('getEnmuMapByListKey', async() => {
    test('Valid enum map', () => {
      const enumList = [
        { name: 'john', age: 18 },
        { name: 'jack', value: 20 }
      ]
      const res = getEnmuMapByListKey(enumList, 'name')
      expect(res).toStrictEqual({
        john: { name: 'john', age: 18 },
        jack: { name: 'jack', value: 20 }
      })
    })
  })
}

export const recordToMappedRecordTuple = <MapKey extends string, MapValue extends string, K extends string, V extends string | number>(obj: Record<K, V>, mapKeys: readonly [MapKey, MapValue]) => {
  const res: {
    [key in typeof mapKeys[number]]: key extends MapKey ? K : (key extends MapValue ? V : never)
  }[] = []
  Object.entries(obj).forEach(([key, value]: [K, V]) => {
    // @ts-expect-error 动态索引签名问题
    const item: {
    [key in typeof mapKeys[number]]: key extends MapKey ? K : (key extends MapValue ? V : never)
  } = {
    [mapKeys[0]]: key,
    [mapKeys[1]]: value
  }
    res.push(item)
  })
  return res
}

// vitest in-source testing
if (import.meta.vitest) {
  // test recordToMappedRecordTuple
  const { describe, expect, it, test } = import.meta.vitest
  describe('recordToMappedRecordTuple', async() => {
    test('Valid recordToMappedRecordTuple', () => {
      const jack = {
        age: 20,
        name: 'jack',
        hobby: 'basketball'
      }
      const res = recordToMappedRecordTuple(jack, ['metric', 'value'])
      expect(res).toEqual([
        { metric: 'age', value: 20 },
        { metric: 'name', value: 'jack' },
        { metric: 'hobby', value: 'basketball' }
      ])
    })
  })
}

// reduce实现的compose
export const compose = (...fns) =>
  fns.slice().reverse().reduce((fn, gn) =>
    (...args) => fn(gn(...args))
  )

// vitest in-source testing
if (import.meta.vitest) {
  // test compose
  const { describe, expect, it, test } = import.meta.vitest
  describe('compose', async() => {
    test('Valid compose', () => {
      const add = (a, b) => a + b
      const pow = (a) => a ** 2
      const res = compose(add, pow)(1, 2)
      expect(res).toBe(9)
    })
  })
}

/**
 * @description: 获取常用类型的构造器字符串
 * @param {any} prop 任意类型参数
 * @return {string} 参数的构造器字符串
 */
export const toType = (prop:any):string => {
  return Object.prototype.toString
    .call(prop)
    .split(' ')[1]
    .replace(']', '')
}

// vitest in-source testing
if (import.meta.vitest) {
  // test toType
  const { describe, expect, it, test } = import.meta.vitest
  describe('toType', async() => {
    test('Valid toType', () => {
      const res = toType('hello')
      expect(res).toBe('String')
    })
  })
}

export const toClass = toType

/**
 * @description: 严格类型判断
 * @param {any} prop 任意类型参数
 * @param {string} propType 构造器字符串
 * @return {boolean} 是否为传入的构造器
 */
export const isType = (prop:any, propType:string):boolean => {
  return toType(prop) === propType
}

// vitest in-source testing
if (import.meta.vitest) {
  // test isType
  const { describe, expect, it, test } = import.meta.vitest
  describe('isType', async() => {
    test('Valid isType', () => {
      const res = isType('hello', 'String')
      expect(res).toBe(true)
    })
  })
}

/**
 * @description: 严格类型判断 - 集合
 * @param {any} prop 任意类型参数
 * @param {array} propTypes 构造器字符串 | 构造器字符串数组
 * @return {boolean} 是否为传入的构造器类型子集
 */
export const isTypes = (prop:any, propTypes:Array<string> | string): boolean => {
  if (!Array.isArray(propTypes as string)) {
    return toType(prop) === propTypes
  } else {
    return propTypes.includes(toType(prop))
  }
}

// vitest in-source testing
if (import.meta.vitest) {
  // test isTypes
  const { describe, expect, it, test } = import.meta.vitest
  describe('isTypes', async() => {
    test('Valid isTypes', () => {
      const res = isTypes('hello', 'String')
      expect(res).toBe(true)
    })
    test('Valid isTypes', () => {
      const res = isTypes('hello', ['String', 'Number'])
      expect(res).toBe(true)
    })
  })
}

/**
 * @description: 获取对象的键是否存在
 * @param {object} obj 一个对象
 * @param {string} key 键
 * @return {boolean} 对象是否具有键
 */
export const hasOwn = (obj:object, key:string):boolean => {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

// vitest in-source testing
if (import.meta.vitest) {
  // test hasOwn
  const { describe, expect, it, test } = import.meta.vitest
  describe('hasOwn', async() => {
    test('Valid hasOwn', () => {
      const res = hasOwn({ name: 'jack' }, 'name')
      expect(res).toBe(true)
    })
  })
}

/**
 * @description: 清空字符串空格
 * @param {string} str  字符串
 * @param {boolean} isInsert 是否去除字符串内部空白符
 * @return {string} 清空空格后的字符串
 */
export const trimAll = (str:string, isInsert:boolean):string => {
  if (!isInsert) {
    return str.trim()
  }
  return str.replace(/\s/gm, '')
}

// vitest in-source testing
if (import.meta.vitest) {
  // test trimAll
  const { describe, expect, it, test } = import.meta.vitest
  describe('trimAll', async() => {
    test('Valid trimAll', () => {
      const res = trimAll('  hello  world  ', false)
      expect(res).toBe('hello  world')
    })
  })
}

/**
 * @description: 格式化数字为一个填充固定字符的字符串
 * @param {number} 数字
 * @param {object} 配置项
 * @return {string} 格式化后的字符串
 */
export const formatNumberOfCode = (
  number:number|string
  , config?:{
    length: number,
    fill:string
  }):string => {
  const defaultConfig = {
    length: 2,
    fill: '0',
    ...config
  }
  const numberStr = number.toString()
  if (numberStr.length >= defaultConfig.length) {
    return numberStr.slice()
  } else {
    return Array.from({ length: defaultConfig.length - numberStr.length }).map(_ => defaultConfig.fill).join('') + numberStr
  }
}

// vitest in-source testing
if (import.meta.vitest) {
  // test formatNumberOfCode
  const { describe, expect, it, test } = import.meta.vitest
  describe('formatNumberOfCode', async() => {
    test('Valid formatNumberOfCode', () => {
      const res = formatNumberOfCode(1, { length: 3, fill: '0' })
      expect(res).toBe('001')
    })
  })
}

/**
 * @description: 格式化 hex 颜色字符串为一个 rgba字符串
 * @param {string} hexValue hex 颜色字符串
 * @param {number} opacity 透明度
 * @return {string} rgba字符串
 */
export const hexToRgba = (hexValue:string, opacity = 1):string => {
  const rgx = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const hex = hexValue.replace(rgx, (m, r, g, b) => r + r + g + g + b + b)
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const r = parseInt(rgb[1], 16)
  const g = parseInt(rgb[2], 16)
  const b = parseInt(rgb[3], 16)
  return `rgba(${r},${g},${b},${opacity})`
}

// vitest in-source testing
if (import.meta.vitest) {
  // test hexToRgba
  const { describe, expect, it, test } = import.meta.vitest
  describe('hexToRgba', async() => {
    test('Valid hexToRgba', () => {
      const res = hexToRgba('#00FFFF', 0.2)
      expect(res).toBe('rgba(0,255,255,0.2)')
    })
  })
}

const hue2rgb = (p:number, q:number, t:number):number => {
  if (t < 0) { t += 1 }
  if (t > 1) { t -= 1 }
  if (t < 1 / 6) { return p + (q - p) * 6 * t }
  if (t < 1 / 2) { return q }
  if (t < 2 / 3) { return p + (q - p) * (2 / 3 - t) * 6 }
  return p
}
/**
 * @description: 格式化 hsl 颜色字符串为一个 rgba字符串
 * @param {string} hslValue hsl 颜色字符串
 * @param {number} opacity 透明度
 * @return {string} rgba字符串
 */
export const hslToRgba = (hslValue:string, opacity = 1):string => {
  const hsl = /hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/g.exec(hslValue)
  const h = parseInt(hsl[1]) / 360
  const s = parseInt(hsl[2]) / 100
  const l = parseInt(hsl[3]) / 100
  let r, g, b
  if (s === 0) {
    r = g = b = l
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }
  return `rgba(${r * 255},${g * 255},${b * 255},${opacity})`
}

// vitest in-source testing
if (import.meta.vitest) {
  // test hslToRgba
  const { describe, expect, it, test } = import.meta.vitest
  describe('hslToRgba', async() => {
    test('Valid hslToRgba', () => {
      const res = hslToRgba('hsl(120, 100%, 50%)', 0.2)
      expect(res).toBe('rgba(0,255,0,0.2)')
    })
  })
}

/**
 * @description: 根据透明度和颜色字符串获取一个转换后的rgba字符串
 * @param {string} colorStr 颜色字符串
 * @param {number} opacity 颜色透明度
 * @return {string} rgab字符串
 */
export const getColorByOpacity = (colorStr:string, opacity = 1):string => {
  // 16进制正则
  const hexReg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  // rgba正则
  const rgbReg = /^rgb\(\s*\d{1,3}\s*\,\s*\d{1,3}\s*\,\s*\d{1,3}\s*\)$/
  // rgba正则
  const rgbaReg =
    /^rgba\((\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*)((?:,\s*[0-9.]*\s*)?)\)$/
  // hsl正则
  const hslReg = /^hsl/
  let rgbaColorStr = ''
  if (hexReg.test(colorStr)) {
    rgbaColorStr = hexToRgba(colorStr, opacity)
  } else if (rgbReg.test(colorStr)) {
    rgbaColorStr = colorStr.replace(/\)/, `,${opacity})`).replace('rgb', 'rgba')
  } else if (rgbaReg.test(colorStr)) {
    rgbaColorStr = colorStr.replace(/\,\d\)/, `,${opacity})`)
  } else if (hslReg.test(colorStr)) {
    rgbaColorStr = hslToRgba(colorStr, opacity)
  } else {
    throw new Error(`The colorStr ${colorStr} is not a correct color`)
  }
  const rgbaColorFormatStr = rgbaColorStr.replace(/\s+/, '')
  return rgbaColorFormatStr
}

// vitest in-source testing
if (import.meta.vitest) {
  // test getColorByOpacity
  const { describe, expect, it, test } = import.meta.vitest
  describe('getColorByOpacity', async() => {
    test('Valid getColorByOpacity Hex', () => {
      const res = getColorByOpacity('#00FFFF', 0.2)
      expect(res).toBe('rgba(0,255,255,0.2)')
    })
    test('Valid getColorByOpacity Hsl', () => {
      const res = getColorByOpacity('hsl(120, 100%, 50%)', 0.2)
      expect(res).toBe('rgba(0,255,0,0.2)')
    })
  })
}

/**
 * @description: 线性计算两个hex颜色之间任意位置的颜色, 并输出rgb数值数组
 * @param {string} startColor 例如: #00FFFF
 * @param {string} endColor 例如: #00FF00
 * @param {number} percentage 例如: 0.75
 * @return { [number, number, number] } 例如: [255, 255, 255]
 */
export const getLinearlyColor = (startColor: string, endColor: string, percentage: number): number[] => {
  const start = hexToRgba(startColor).split('(').at(1).split(')').at(0).split(',')
  const end = hexToRgba(endColor).split('(').at(1).split(')').at(0).split(',')
  start.pop()
  end.pop()
  const [rs, gs, bs] = start.map(Number)
  const [re, ge, be] = end.map(Number)
  const getValue = ([s, e]) => {
    return (e - s) * percentage + s
  }
  return [[rs, re], [gs, ge], [bs, be]].map(getValue).map(Math.ceil)
}

// vitest in-source testing
if (import.meta.vitest) {
  // test getLinearlyColor
  const { describe, expect, it, test } = import.meta.vitest
  describe('getLinearlyColor', async() => {
    test('Valid getLinearlyColor', () => {
      const res = getLinearlyColor('#00FFFF', '#00FF00', 0.75)
      expect(res).toEqual([0, 255, 64])
    })
  })
}

export const setColorOpacity = getColorByOpacity

// 只消费最后一次“调用”的结果，而不是最后一个返回的结果
// 1.同一个函数只消费最后一次调用的结果
// 2.不同函数各自消费自己最后一次的调用结果
// 3.每条请求并没有取消，只是没有消费它的结果
export const doJobLastInvoked = (function() {
  const token = new WeakMap()
  return function addJob<T, U>(fn: (...args: T[]) => Promise<U>) {
    const ticket = Symbol('ticket')
    token.set(fn, ticket)
    const p = (...args: T[]) => new Promise((resolve: (value: U) => void, reject) => {
      fn(...args).then(res => {
        const currentToken = token.get(fn)
        if (currentToken && currentToken === ticket) {
          resolve(res)
          token.delete(fn)
        } else {
          reject(new Error('callback is out of date.'))
        }
      })
    })
    return p
  }
})()

// 检测传入的Promise是否处于Pending状态
export const isPending = async<T>(p: Promise<T>) => {
  const empty = {}
  try {
    const resp = await Promise.race([p, empty])
    return resp === empty
  } catch (e) {
    return false
  }
}

/**
 * @description: 高频调用同一个js函数操作dom，造成回流重绘的性能瓶颈时使用
 * @param {() => Boolean} reflowFn
 */
export function useRAF(reflowFn: (...args: any[]) => boolean) {
  const step = (time: number) => {
    const endPoint = reflowFn()
    if (!endPoint) {
      requestAnimationFrame(step)
    }
  }
  requestAnimationFrame(step)
}

// 从一个对象中排除一些不想被引用的key
export const omit = <T extends Record<string, any>, K extends Extract<keyof T, string>[]>(obj: T, keys: K, isDeepClone = false) => {
  if (Object.prototype.toString.call(obj) !== '[object Object]') { return obj }
  const res = {} as Omit<T, K[number]>
  const needPick = (key: Extract<keyof T, string>) : key is Exclude<Extract<keyof T, string>, K[number]> => {
    return !keys.includes(key)
  }
  for (const key in obj) {
    if (needPick(key)) {
      res[key] = isDeepClone ? cloneDeep(obj[key]) : obj[key]
    }
  }
  return res
}

const { decrypt, encrypt } = common
export const getShareConfig = (): {
  isShareLink: boolean
  shareId: string | null
} => {
  const isShareLink = location.href.includes('shareId')
  const searchParams = new URLSearchParams(location.search)
  const shareId = searchParams.get('shareId')
  if (shareId) {
    const decryptShareId = decrypt(shareId)
    return {
      isShareLink,
      shareId: decryptShareId
    }
  }
  return {
    isShareLink,
    shareId
  }
}

/**
 * @description: 获取转换后的columns 给予下载列表
 * @param {array} columns 转换的列数组
 * @param {array} mapKey 转换的字段数组集合 [label , prop]
 * @return {array} transFormColumn 转换后的列表集合
 */
export const getDownLoadFields = (columns:object[] = [], mapKey:string[] = []):object[] => {
  const [mapLabel = 'label', mapProp = 'prop'] = mapKey
  const transFormColumns:object[] = columns.map(item => {
    return {
      'showName': item[mapLabel],
      'field': item[mapProp]
    }
  })
  return transFormColumns
}

/**
 * 带有防抖功能的Ref
 * @param value
 * @param interval
 */
export const debounceRef = <T>(value: T, interval: number = 500): Ref<T> => {
  return customRef((track, trigger) => {
    return {
      get() {
        track()
        return value
      },
      set: debounce(newValue => {
        value = newValue
        trigger()
      }, interval)
    }
  })
}
