
const state = () => ({
  filterCache1p: {},
  filterCache3p: {},
  realTimeFilterCache1p: {},
  realTimeFilterCache3p: {}
})

const actions = {}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isLogoutClearStorage: false,
    isModuleStorage: true,
    watchFields: ['filterCache3p', 'filterCache1p', 'realTimeFilterCache3p', 'realTimeFilterCache1p']
  }
}
