import { getCustomLayerConfig, updateCustomLayerConfig } from '@!/api/Home/index.js'
import * as HomeConst from '@!/const/HomeComp.js'
import { diffCustomLayerConfig } from '@commerceCommon/utils/customLayer'

const getUID = () => localStorage.getItem('uid')
const stateKeys = {
  get customLayerConfigKey() {
    return getUID() + '_3p_homeCustomLayerConfig'
  },
  get vendorConfigKey() {
    return getUID() + '_1p_homeVendorConfig'
  },
  get timeDimensionBySalesKey() {
    return getUID() + '_3p_timeDimensionBySales'
  },
  get tdBySalesTodaySettingKey() {
    return getUID() + '_3p_tdBySalesTodaySetting'
  },
  get topShakersSortTypeKey_3p() {
    return getUID() + '_3p_topShakersSortType'
  },
  get topShakersSortTypeKey_1p() {
    return getUID() + '_1p_topShakersSortType'
  },
  get topShakersChainTypeKey_3p() {
    return getUID() + '_3p_topShakersChainType'
  },
  get topShakersChainTypeKey_1p() {
    return getUID() + '_1p_topShakersChainType'
  },
  get InventoryOverview_1p() {
    return getUID() + '_1p_inventoryOverview'
  },
  get InventoryOverview_3p() {
    return getUID() + '_3p_inventoryOverview'
  }
}

const state = () => ({
  // 自定义布局配置
  customLayerConfig:
    JSON.parse(sessionStorage.getItem(stateKeys.customLayerConfigKey) || 'null') ||
    HomeConst.getDefaultCustomLayerConfig(),
  vendorConfig:
    JSON.parse(sessionStorage.getItem(stateKeys.vendorConfigKey) || 'null') || [],
  vendorInventoryOverview: JSON.parse(sessionStorage.getItem(stateKeys.InventoryOverview_1p) || 'null') || [],
  sellerInventoryOverview: JSON.parse(sessionStorage.getItem(stateKeys.InventoryOverview_3p) || 'null') || [],
  // 1p 首页 子组件传参缓存
  homeWidgetParamsBy1p: {
    childrenParams: {}
  },
  // 3p 首页 子组件传参缓存
  homeWidgetParamsBy3p: {
    childrenParams: {}
  },
  // 测试深度监听
  testDeep: {}
})

const actions = {
  // 设置vendor自定义布局配置
  setVendorLayerConfig(configProp) {
    this.vendorConfig = configProp
    sessionStorage.setItem(stateKeys.vendorConfigKey, JSON.stringify(configProp))
  },
  // 设置自定义布局配置
  setCustomLayerConfig(configProp) {
    this.customLayerConfig = configProp
    sessionStorage.setItem(stateKeys.customLayerConfigKey, JSON.stringify(configProp))
  },
  // 获取自定义布局配置
  getCustomLayerConfigPro() {
    const layerConfigStorage = sessionStorage.getItem(stateKeys.customLayerConfigKey)
    if (layerConfigStorage) {
      return Promise.resolve(JSON.parse(layerConfigStorage))
    }
    return getCustomLayerConfig()
      .then(res => {
        const originCustomLayerConfig = res ? [...JSON.parse(res)] : []
        const localCustomLayerConfig = HomeConst.getDefaultCustomLayerConfig()
        const { nextCustomLayerConfig, isUpdateOriginCustomLayer } = diffCustomLayerConfig(originCustomLayerConfig, localCustomLayerConfig)
        // 更新远程布局
        if (isUpdateOriginCustomLayer) {
          console.log('update origin customLayerConfig')
          updateCustomLayerConfig({
            detail: JSON.stringify(nextCustomLayerConfig)
          })
        }
        console.log('set store customLayerConfig', nextCustomLayerConfig)
        this.setCustomLayerConfig(nextCustomLayerConfig)
        return Promise.resolve(nextCustomLayerConfig)
      }).catch(err => {
        return Promise.reject(err)
      })
  },
  // 设置vendor自定义布局配置
  setVendorInventoryOverviewDrag(configProp) {
    this.vendorInventoryOverview = configProp
    sessionStorage.setItem(stateKeys.InventoryOverview_1p, JSON.stringify(configProp))
  },
  // 设置vendor自定义布局配置
  setSellerInventoryOverviewDrag(configProp) {
    this.sellerInventoryOverview = configProp
    sessionStorage.setItem(stateKeys.InventoryOverview_3p, JSON.stringify(configProp))
  }
}
export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    watchFields: [
      'homeWidgetParamsBy1p',
      'homeWidgetParamsBy3p',
      'testDeep'
    ],
    // 表示退出登录时保留所有 watchFields的 持久化数据
    isLogoutClearStorage: false
    /* 也可以使用以下写法：意为退出登录时清除所有持久化数据, 但是保留 logoutWatchFields 的持久化数据
      isLogoutClearStorage: true,
      logoutWatchFields: ['homeWidgetParamsBy1p', 'homeWidgetParamsBy3p']
    */
  }
}

