import localForage from "localforage"
import { useUserStore } from "@/store"
import { indexDBname } from "./config"
import { SENDER, CONVERSATION_TYPE } from "./constants"
// 根据routePath获取对应的问题预设
import { getCopilotQuestionsPresetsByRoute, getCopilotDataQueryPresetsByRoute } from "../../utils/copilot"

const forageInstances = Object.values(CONVERSATION_TYPE).reduce((prev, curr) => {
  return {
    ...prev,
    [curr]: localForage.createInstance({
      driver: localForage.INDEXEDDB,
      name: "PacvueCopilot",
      storeName: curr,
      description: "Conversation data of Copilot."
    })
  }
}, {})

const initChat = async ({ isClear = false, conversationType = CONVERSATION_TYPE.FEATURE_INSTRUCTION, routePath = "" } = {}) => {
  const userStore = useUserStore()
  const storageKey = `${userStore.user?.userId ?? ""}_CopilotLastRoutePath`
  const lastRoutePath = sessionStorage.getItem(storageKey) || ""
  let currTimestamp = new Date().getTime()

  // 当前路由菜单对应i18N文案
  let moduleName = routePath ? window.menuUrlItemMap[routePath]?.i18N : ""

  // Feature Instruction 预设
  let FI_Presets = {}
  let { welcomeMsg: FI_WelcomeMsg = $t("amskey3302"), q: FI_DefaultQuest = [] } = FI_Presets
  // Data Query 预设
  let DQ_Presets = {}
  let { welcomeMsg: DQ_WelcomeMsg = $t("amskey3301"), q: DQ_DefaultQuest = [] } = DQ_Presets

  // 根据路由获取 预设
  if (routePath) {
    FI_Presets = getCopilotQuestionsPresetsByRoute(moduleName)
    FI_WelcomeMsg = FI_Presets.welcomeMsg || $t("amskey3302")
    FI_DefaultQuest = FI_Presets.q || []

    DQ_Presets = getCopilotDataQueryPresetsByRoute(moduleName)
    DQ_WelcomeMsg = DQ_Presets.welcomeMsg || $t("amskey3301")
    DQ_DefaultQuest = DQ_Presets.q || []
  }
  // 路由不同时清空上次会话数据
  if (lastRoutePath !== routePath) {
    isClear = true
  }
  sessionStorage.setItem(storageKey, routePath)

  // 初始化数据
  const initData = [
    {
      type: "time",
      message: currTimestamp,
      key: new Date() + "time"
    },
    {
      type: "message",
      status: "success",
      allData: null,
      message: "",
      sender: SENDER.MODEL,
      key: new Date() + "message"
    }
  ]

  // 读取本地存储数据
  const forageInstance = forageInstances[conversationType]
  const data = await forageInstance.getItem(indexDBname())
  let resData = []
  if (data && !isClear) {
    resData = data
    if (resData[resData.length - 1].type === "Thinking") resData.pop()
  } else {
    resData = initData
  }

  // 修改提问预设
  switch (conversationType) {
    // Feature Instruction
    case CONVERSATION_TYPE.FEATURE_INSTRUCTION:
      resData[1].message = `<div class='mb-2'>${FI_WelcomeMsg}</div>`
      if (FI_DefaultQuest.length > 0) {
        FI_DefaultQuest.forEach(
          (preset) =>
            (resData[1].message += `
            <div class="color-primary action-chat preset-wrapper" style="white-space: initial; align-items: initial;" attr-data='sendCopy'>
              <span class='preset-ind'></span>
              <span class='action-chat preset-example' attr-data='sendCopy'>${preset}</span>
            </div>
          `)
        )
      }
      break
    // Data Query
    case CONVERSATION_TYPE.DATA_QUERY:
      resData[1].message = `
        <div class='mb-2'>${DQ_WelcomeMsg}</div>
        ${DQ_DefaultQuest.map(
          (preset) =>
            `<div class="color-primary action-chat preset-wrapper" style="white-space: initial; align-items: initial;" attr-data='sendCopy'>
                <span class='preset-ind'></span>
                <span class='action-chat preset-example' attr-data='sendCopy'>${preset}</span>
              </div>`
        ).join("")}
      `
      if (resData.length <= 2) {
        resData.push({
          type: "tips",
          message: $t("amskey3305"),
          key: new Date() + "tips"
        })
      }
      // data query 强制加上侵权保护声明文本
      if (resData[2].type !== "tips") {
        resData.splice(2, 0, {
          type: "tips",
          message: $t("amskey3305"),
          key: new Date() + "tips"
        })
      }
      break
    case CONVERSATION_TYPE.INSIGHT:
      // v2.2 不上, 暂时注掉
      // resData[1].message = `
      //   <div class='mb-2'>${$t("amskey3304")}</div>
      //   ${Object.entries(insightTypeFieldMap[productLine])
      //     .map(
      //       ([type, field]) => `
      //       <div class="color-primary action-chat preset-wrapper" style="white-space: initial; align-items: initial;" attr-data='sendCopy'>
      //         <span class='preset-ind'></span>
      //         <span class='action-chat preset-example' attr-data='sendInsightType' data-insight-field='${field}'>${$t(type)}</span>
      //       </div>
      //   `
      //     )
      //     .join("")}
      // `
      resData[1].message = `<div>${$t("amskey3304")}</div>`
      break
  }
  // 当不存在缓存数据或者需要清空数据时, 重新写入数据
  if (!data || isClear) {
    await forageInstance.setItem(indexDBname(), resData)
  }
  return resData
}

const updatePresets = async ({ conversationType, routePath }) => {
  const moduleName = window.menuUrlItemMap[routePath]?.i18N || ""
  const FI_Presets = getCopilotQuestionsPresetsByRoute(moduleName)
  let FI_WelcomeMsg = FI_Presets.welcomeMsg
  let FI_DefaultQuest = FI_Presets.q
  if (conversationType === CONVERSATION_TYPE.FEATURE_INSTRUCTION && FI_DefaultQuest.length > 0) {
    const forageInstance = forageInstances[CONVERSATION_TYPE.FEATURE_INSTRUCTION]
    const data = await forageInstance.getItem(indexDBname())
    data[1].message = `<div class='mb-2'>${FI_WelcomeMsg}</div>`
    FI_DefaultQuest.forEach(
      (preset) =>
        (data[1].message += `
          <div class="color-primary action-chat preset-wrapper" style="white-space: initial; align-items: initial;" attr-data='sendCopy'>
            <span class='preset-ind'></span>
            <span class='action-chat preset-example' attr-data='sendCopy'>${preset}</span>
          </div>
        `)
    )
    await forageInstance.setItem(indexDBname(), data)
    return data
  }
}

export { forageInstances, initChat, updatePresets }
