import type { Ref, ComponentPublicInstance } from 'vue'
import type { Step } from 'driver.js'
import Driver from 'driver.js'
import { isRef } from 'vue'
import { PiniaPersistList } from '@!/store'
/*
  openDriver(
    iconSettingRef: icon实例
  )
*/

const WatchFields = [
  'isAlertDriver'
]

const state = () => {
  return {
    // 引导是否初始化完成
    isAlertDriver: false,
    // 引导是否打开状态中
    isAlertDriverOpening: false
  }
}

const getters = {
  // 是否为初始状态
  isDriverInitState() {
    return !this.isAlertDriver && !this.isAlertDriverOpening
  }
}

const actions = {
  // 缓存写入方法,命名严格按照Is + 参数名,否则存储不了
  setIsAlertDriver(val:boolean):void {
    this.isAlertDriver = val
  },
  // 打开引导
  openDriver(iconSettingRef:Ref< ComponentPublicInstance > | HTMLElement):void {
    // 初始化完成
    if (this.isAlertDriver) { return }
    // 打开状态
    if (this.isAlertDriverOpening) { return }
    let tableRefEle: HTMLElement
    if (isRef(iconSettingRef)) {
      tableRefEle = iconSettingRef.value.$el
    } else {
      tableRefEle = iconSettingRef
    }
    // iconSettingRef 不存在
    if (!iconSettingRef) {
      console.log('icon is not be null')
      return
    }
    const driverSteps:Step = {
      element: tableRefEle,
      popover: {
        className: 'AlertSettingDriver', // className to wrap this specific step popover in addition to the general className in Driver options
        title: ' ',
        description: `<div style='display:flex;flex-direction: column;align-items:flex-start'><div style="font-size: 18px;color: #45464f;line-height: 22px;
        font-family: Inter, Inter-Medium;font-weight: 500;text-align: left;">${$t('alert.updateAboutSettings')}</div>
                        <div style='margin-top: 16px;font-size: 14px;font-family: Inter, Inter-Regular;font-weight: 400;
                        text-align: LEFT;color: #66666c;line-height: 20px;'>${$t('alert.updateTip')}</div>
                        <div style='font-size: 14px;font-family: Inter, Inter-Regular;font-weight: 400;
                        text-align: LEFT;color: #66666c;line-height: 20px;'>${$t('alert.youCanTry')}</div>
                      </div>`,
        showButtons: true, // show control buttons in footer
        closeBtnText: $t('common.ok'), // Text on the close button for this step
        position: 'bottom-right',
        offset: 6
      }
    }
    const driverIntance = new Driver({
      padding: 8,
      allowClose: false,
      keyboardControl: false,
      onDeselected: () => {
        this.setIsAlertDriver(true)
        this.isAlertDriverOpening = false
        setTimeout(() => {
          document.querySelector('.AlertSettingDriver').remove()
        })
      }
    })
    //   // 设置高亮层遮罩蒙层
    driverIntance.highlight(driverSteps)
    this.isAlertDriverOpening = true
  },
  // 初始化driver
  resetDriver():void {
    if (this.isAlertDriverOpening) {
      return
    }
  },
  setUserPersist() {
    const userid = localStorage.getItem('uid')
    WatchFields.forEach((fieldName) => {
      const storageKey = PiniaPersistList.includes(fieldName) ? 'storage_' : ''
      const storeKey = `${storageKey}${userid}_${fieldName}`
      const storeStr = localStorage.getItem(storeKey)
      if (storeStr) {
        this[fieldName] = JSON.parse(storeStr)
      }
    })
  }
}

export default {
  state,
  getters,
  actions,
  persist: {
    enabled: true,
    isCommon: true,
    watchFields: WatchFields,
    isLogoutClearStorage: true,
    logoutWatchFields: ['isAlertDriver']
  }
}
