import { useUserStore } from '@!/store'
import { requestOfCallCancel } from '@!/utils/useCancelRequest'
import { $request, $http3p } from '../http'
const { VITE_APP_ADMIN_MIDDLE } = import.meta.env

/**
 * @description: 同步用户Setting页配置
 * @return {Promise<{ dateFormat: string, defaultCurrency: string, isExchange: number, randA: string, themeColour: 'gray' | 'white' | 'black', timeZone: string, forceMFA: boolean }>}
 */
export async function getSettings(uid) {
  uid = useUserStore().user?.userId
  const data = await $request({
    url: `${VITE_APP_ADMIN_MIDDLE}platform/getUserPreferences/${uid}`,
    method: 'GET',
    isIgnoreRequestRegister: true
  })
  data.defaultCurrency = ['BE', 'DE', 'ES', 'FR', 'IT', 'NL'].includes(data.defaultCurrency) ? 'DE' : data.defaultCurrency
  return data
}

export function getCommerceSettings() {
  return $request({
    url: `${VITE_APP_ADMIN_MIDDLE}user/getCommerceSettings`,
    method: 'GET',
    isIgnoreRequestRegister: true
  })
}

export function setSettings(data) {
  return requestOfCallCancel({
    baseURL: VITE_APP_ADMIN_MIDDLE,
    url: `/platform/setUserPreferences`,
    method: 'post',
    data: {
      ...data,
      language: localStorage.getItem('locale')
    },
    _autoCancel: false,
    _ignoreRouterProxy: true,
    _cancel: () => {},
    isIgnoreRequestRegister: true
  })
}

// 获取 用户CurrencyExchangeRate Dialog 内容
export const getExchangeRateDialog = data => {
  return $http3p({
    url: 'Setting/getExchangeRate',
    method: 'post',
    data
  })
}
