import Main from '@pacvue/frame/layout/Main.vue'
import { useHomeStore, useUserStore } from '@!/store'
import { destroyChart } from '@!/utils/ts-common'

export default {
  path: '/',
  component: Main,
  meta: {
    auth: true,
    id: 666
  },
  sort: 100,
  redirect: '/Home',
  children: [
    {
      path: '/Home',
      component: () => destroyChart(() => import('@!/views/Home/index.vue')),
      name: 'Home',
      meta: {
        autoTestPrefix: 'auto_bm101'
      }
    }
  ],
  // 动态首页widget components
  async beforeEnter(to, from, next) {
    const homeStore = useHomeStore()
    const userStore = useUserStore()
    const synchronizations = [
      // 同步 home - sales TimeDimensionType
      homeStore.getCustomLayerConfigPro
    ]
    if (userStore.user) {
      await Promise.all(synchronizations.map(fn => fn()))
      next()
    } else {
      // 手动清除缓存了
      next('/login')
    }
  }
} as import('vue-router').RouteLocationPathRaw
