
const state = () => ({
  alertCacheBy1P: {
    tabObj: {},
    problematicType: '',
    filterCache: {}
  },
  alertCacheBy3P: {
    tabObj: {},
    problematicType: '',
    filterCache: {}
  }
})

const actions = {
}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isLogoutClearStorage: false,
    isModuleStorage: true,
    watchFields: ['alertCacheBy1P', 'alertCacheBy3P']
  }
}
