import axios from 'axios'
import { attempt, cloneDeep, isArray, isObject, isString, intersection, merge } from 'lodash-es'
import request from '@pacvue/frame/request'
import { useRoute } from 'vue-router'

/*
  示例参考  /Demo/CancelRequest
  Axios 配置扩展参数
    Prop
      _tag // String | Array 请求静态标记
      _ignoreRouterProxy(由 _ignoreCancel 代替 , 后面会废弃) // Boolean 是否在路由拦截运行时  忽略取消执行
                         // 由于无法精准判定函数运行时是否在 router 拦截中
      _ignoreCancel // boolean 是否忽略取消
      _autoCancel: true,// Boolean 自动取消-开启
      _autoCancelDiffProp // String 自动取消-对比键
                          // 键以特殊分隔符 |(管道符号)分割对比的每个key
                          // 字符串会以 | 分割为字符串数组
      _autoCancelDiffMode // String 自动取消-对比模式
                          // and | or
                          // and 与模式 会按顺序迭代所有的diffProp 全部对比为true 才会自动取消
                          // or 或模式 会按顺序迭代diffProp 有一个为true 就会取消
      _autoCancelLoop // Boolean 自动取消-是否全部迭代
                      // false 在Map集合中找到对比的唯一MapItem  结束循环
                      // true 在Map集合中找到所有达成对比条件的MapItem
    Method( private )
      _cancel // 取消请求
      _getTag // 获取静态标记
*/

// ## 示例 参考 demo/cancelReuqest
// 添加可以 取消请求的 包裹函数
// 具体请求示例参考 sales Api 与 sales 页面
// 函数返回一个 Promise对象  Promose对象具有一个 _cancel自定义私有方法
// 调用 _cancel 方法 取消 xhr 请求 与 promise 对象

// 创建一个map对象收集所有请求实例
// 请求实例会在 Promise.finally 之后从 Map中移除
const cancelRequestMap = new Map()
const tagConstKey = '_tag'
// 重复取消的 cancel.message
export const RepeatCancelMessage = 'CANCEL'
// 主动取消的 cancel.message( 兼容性保留 )
export const ActiveCancelMessage = RepeatCancelMessage

/*
// 用于在 APi cancel 回调中判断请求是否在进行（是否应该关闭loading）
// 判断tag请求是否还在 请求Map队列中
// Prop:
// tag : String | Array tag标记
// isHash ?:Boolean  是否强制匹配
                    强制匹配情况下会进行 === 运算( String )
                    非强制匹配情况下进行 includes 运算( String )

*/

/**
 * @description: 获取 tag 是否在请求列表队列中
 * @param {string | string[]} tag
 * @param {boolean} [isHash]
 * @return {boolean}
 */
export function hasTagRequest(tag, isHash = false) {
  if (!tag) {
    throw new Error('Arg: tag is not defined')
  }
  const joinSymbol = '_>'
  let tagOfSymbolStr
  if (typeof tag === 'string') {
    tagOfSymbolStr = tag
    // throw Error('Arg: tag type must be String')
  } else if (Array.isArray(tag)) {
    tagOfSymbolStr = tag.join(joinSymbol)
  } else {
    throw new TypeError('Arg: tag type must be a String or Array')
  }
  let hasTag = false
  for (const cancelRequestConfig of cancelRequestMap.keys()) {
    const configTags = cancelRequestConfig[tagConstKey]
    let configTagsOfSymbolStr
    if (typeof configTags === 'string') {
      configTagsOfSymbolStr = configTags
    } else if (Array.isArray(configTags)) {
      configTagsOfSymbolStr = configTags.join(joinSymbol)
    }
    if (!configTagsOfSymbolStr) {
      continue
    } else {
      // 非强制匹配模式下
      if (!isHash) {
        if (configTagsOfSymbolStr.includes(tagOfSymbolStr)) {
          hasTag = true
          break
        }
      } else {
        if (configTagsOfSymbolStr === tagOfSymbolStr) {
          hasTag = true
          break
        }
      }
    }
  }
  return hasTag
}
/*
  // 根据 配置项取消 请求
   Prop:
    requestConfig: Object 请求配置项
    otherConfig ->{
      ...cancelProps
    }
*/

/**
 * @description: 依据请求配置取消请求队列中的请求配置
 * @param {Record<string,any>} requestConfig
 * @param {Record<string,any>} [otherConfig]
 * @return {void}
 */
const cancelRequestByConfig = (requestConfig, otherConfig) => {
  const cancelProps = {
    // 是否为重复取消
    ...otherConfig
  }
  const requestCancelFun = cancelRequestMap.get(requestConfig)
  if (requestCancelFun && typeof requestCancelFun === 'function') {
    requestCancelFun(cancelProps)
  }
}

/*

 针对局域视图模块请求取消
 清除标记请求
 Prop:
  tagProp: String | Array | undefined  数组形式可以清楚多个tag 请求实例
           不传参的情况下  默认清除当前收集的所有请求示例
  otherConfig ->{
    isMixed: Boolean 是否交集判断（接口tag 与 tagProp 存在交集）应对子父模块多tag的情况
    isHashCancel: boolean 是否强制取消 在接口配置 _ignoreRouterProxy | _ignoreCancel 时 可以强制取消
    cancelMessage: string 取消返回的文本 默认 RepeatCancelMessage
  }
*/

/**
 * @description: 取消一个请求根据 tag
 * @param {string|string[]} tagProp
 * @param {{ isMixed:boolean }} [otherConfig]
 * @return {void}
 */
export function cancelTagRequest(tagProp, otherConfig) {
  const cancelProps = {
    // 是否进行交集判断 - tag
    isMixed: true,
    ...otherConfig
  }
  const tagPropOfArr = []
  if (tagProp) {
    if (typeof tagProp === 'string') {
      tagPropOfArr.push(tagProp)
    } else if (Array.isArray(tagProp)) {
      tagPropOfArr.push(...tagProp)
    } else {
      throw new TypeError('Args: tagProp must be a vaild String or Array<string>')
    }
  }
  for (const cancelRequestConfig of cancelRequestMap.keys()) {
    // const requestCancelFun = cancelRequestMap.get(cancelRequestConfig)
    if (tagPropOfArr.length) {
      let nextClearAuto
      const cancelFunOfTag = cancelRequestConfig[tagConstKey]
      // 交集判断
      if (cancelProps.isMixed && Array.isArray(cancelFunOfTag)) {
        nextClearAuto = Boolean(intersection(tagPropOfArr, cancelFunOfTag)?.length)
      } else {
        nextClearAuto = tagPropOfArr.includes(cancelFunOfTag)
      }
      if (nextClearAuto) {
        cancelRequestByConfig(cancelRequestConfig, cancelProps)
      }
    } else {
      cancelRequestByConfig(cancelRequestConfig, cancelProps)
    }
  }
}
/**
 * @description: 清除所有 tag request
 * @param {{ isMixed:boolean }} [otherConfig]
 * @return {void}
 */
export const clearTagRequest = (otherConfig) => {
  cancelTagRequest(undefined, otherConfig)
}

/**
 * @typedef {object} AutoCancelConfig
 * @property {boolean} [_autoCancel] - 是否自动取消
 * @property {string} [_autoCancelDiffProp] - 自动取消对比key
 * @property {boolean} [_autoCancelDiffMode] - 自动取消对比模式
 * @property {boolean} [_autoCancelLoop] - 是否全部迭代
 */

/**
 * @description:  获取默认自动取消配置
 * @returns {AutoCancelConfig} DefaultAutoCancelConfig
 */
const getDefaultAutoCancelConfig = () => {
  return {
    // 是否自动取消
    _autoCancel: true,
    // 自动取消对比key - 基于 axios config
    _autoCancelDiffProp: ' url | baseURL | method | data | params',
    // 自动取消对比模式
    _autoCancelDiffMode: 'and',
    // 是否全部迭代
    _autoCancelLoop: false
  }
}

/*
// 判断对比值的有效性
const isCancelDiffInvalidVal = (val) => {
  const invalidVals = [null, undefined, true, false]
  return invalidVals.includes(val)
}
*/

/**
 * @description: 获取两个值的对比结果
 * @param {*} val
 * @param {*} diffVal
 * @return {boolean}
 */
const getCancelDiffResult = (val, diffVal) => {
  const valType = Object.prototype.toString.call(val)
  const diffValType = Object.prototype.toString.call(diffVal)
  // 如果两个类型不等
  if (valType !== diffValType) {
    return false
  }
  // 是否需要字符序列化类型
  const needToJsonTypes = ['[object Array]', '[object Object]']
  if (needToJsonTypes.includes(valType)) {
    return JSON.stringify(val) === JSON.stringify(diffVal)
  } else {
    return val === diffVal
  }
}
/**
 * @description: 根据请求配置取消请求任务
 * @param {AutoCancelConfig} requestConfig
 * @return {void}
 */
export const autoCancelRequestOfConfig = (requestConfig) => {
  // 自动取消上一次请求
  const diffProps = requestConfig._autoCancelDiffProp.split('|')
  if (!diffProps.length) {
    console.warn('[CancelRequest Warning]: _autoCancelDiffProp is not a voild string')
    return
  }
  for (const cancelRequestConfig of cancelRequestMap.keys()) {
    const diffResultList = []
    for (let i = 0; i < diffProps.length; i++) {
      const trimPropKey = diffProps[i].trim()
      // key 为无效字符
      if (!trimPropKey) {
        continue
      }
      const cancelRequestConfigVal = cancelRequestConfig[ trimPropKey ]
      const requestConfigVal = requestConfig[ trimPropKey ]
      const diffResult = getCancelDiffResult(requestConfigVal, cancelRequestConfigVal)
      // 两个对比值的 value 是否是一个有效值
      const diffValType = typeof requestConfigVal
      if (diffResult && (diffValType === undefined)) {
        continue
      }
      // 收集对比结果
      diffResultList.push(diffResult)
      // 如果条件对等
      if (diffResult) {
        // 如果是 或 模式 直接跳出
        if (requestConfig._autoCancelDiffMode === 'or') {
          break
        }
      } else {
        // 条件不对等 且 模式是 与 模式 跳出
        if (requestConfig._autoCancelDiffMode === 'and') {
          break
        }
      }
    }
    // 没有显示的对比结果 跳过
    if (diffResultList.length === 0) {
      continue
    }
    // 具有不对等条件
    // 跳过本次循环
    if (diffResultList.includes(false)) {
      continue
    } else {
      // 全部对等 取消请求
      cancelRequestByConfig(cancelRequestConfig)
      // 如果是循环模式
      if (requestConfig._autoCancelLoop) {
        continue
      } else {
        // 不是循环模式直接跳出
        break
      }
    }
  }
}

/**
 * @description: 构建一个取消请求Axios实例
 * @param {object} requestInstance - Axios请求实例
 * @param {Record<string,any>} [wrapDefaultConfig] - 同 axiosConfig 包裹函数默认参数配置
 * @return {(config:Record<string,any>)=>Promise<any>} - 返回一个函数-Axios请求实例结果
 */
export function cancelRequestWrap(requestInstance, wrapDefaultConfig) {
  // Prop:
  // config: Object->{ ...AxiosConfig }

  const requestOfCallCancel = (config) => {
    if (!config) {
      throw new Error('Missing required parameters of config')
    }
    const mergeRequestConfig = attempt(() => {
      if (wrapDefaultConfig) {
        if (!isObject(wrapDefaultConfig)) {
          throw new TypeError('Arg: wrapDefaultConfig type must be Object')
        }
        const deepWrapConfig = cloneDeep(wrapDefaultConfig)
        return merge(deepWrapConfig, cloneDeep(config))
      } else {
        return cloneDeep(config)
      }
    })

    // 以当前路由 path 作为 tag默认标记
    const assCancelConfig = {}
    const requestTag = requestOfCallCancel[tagConstKey]
    // 打上tag标记
    if (requestTag) {
      // 转换为数组保存
      if (isString(requestTag)) {
        assCancelConfig[tagConstKey] = [requestTag]
      }
      if (isArray(requestTag)) {
        assCancelConfig[tagConstKey] = requestTag
      }
    }
    // 获取运行时 route path 作为tag 第一标记
    const route = useRoute()
    if (route && route.path) {
      // 没有 tag 标记 初始化
      if (!assCancelConfig[ tagConstKey ]) {
        assCancelConfig[ tagConstKey ] = []
      }
      assCancelConfig[tagConstKey].unshift(route.path)
    }
    // 合并
    Object.assign(assCancelConfig, mergeRequestConfig)
    const hasCancelTokenProp = 'cancelToken' in assCancelConfig
    let cancelSouce
    let cancelSouceToken
    // 如果参数 cancelToken 已经存在 直接取消
    if (!hasCancelTokenProp) {
      cancelSouce = axios.CancelToken.source()
      cancelSouceToken = cancelSouce.token
      const mixinCancelProps = {
        cancelToken: cancelSouceToken,
        // 取消函数
        _cancel: (cancelProps = {}) => {
          const { isHashCancel = false, cancelMessage = RepeatCancelMessage } = cancelProps
          if (!cancelSouce) {
            return
          }
          // 是否忽略路由拦截
          if (
            assCancelConfig._ignoreRouterProxy/* 即将废弃 */ ||
            assCancelConfig._ignoreCancel
          ) {
            // 如果不是强制取消 阻断
            if (!isHashCancel) {
              return
            }
          }

          if (cancelSouce.cancel && typeof cancelSouce.cancel === 'function') {
            // 默认主动取消
            cancelSouce.cancel(cancelMessage)
          }
          cancelSouce = null
          cancelSouceToken = null
        },
        // 获取 tag标记
        _getTag: () => {
          return assCancelConfig[tagConstKey]
        },
        ...getDefaultAutoCancelConfig()
      }
      Object.keys(mixinCancelProps).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(assCancelConfig, key) === false) {
          assCancelConfig[key] = mixinCancelProps[key]
        }
      })
    }

    // 自动取消上一次请求
    if (assCancelConfig._autoCancel) {
      autoCancelRequestOfConfig(assCancelConfig)
    }
    const apiInstancePro = requestInstance(assCancelConfig)
    if (!hasCancelTokenProp) {
      // 绑定 method -> request promise
      apiInstancePro._cancel = assCancelConfig._cancel
      apiInstancePro._getTag = assCancelConfig._getTag
      cancelRequestMap.set(assCancelConfig, assCancelConfig._cancel)
      // 请求实例 Promise 完成之后都会从 map中清除掉 回收内存
      apiInstancePro
        .catch((err) => {
        // 添加catch 处理主动cancel 阻止控制台错误捕获
          if (err?.message === RepeatCancelMessage) {
            return
          }
        })
        .finally(() => {
          if (!hasCancelTokenProp) {
            if (cancelRequestMap.has(assCancelConfig)) {
              cancelRequestMap.delete(assCancelConfig)
            }
          }
        })
    }
    return apiInstancePro
  }
  // 设置包裹函数标记
  requestOfCallCancel[tagConstKey] = ''
  requestOfCallCancel._setTag = (tagProp) => {
    requestOfCallCancel[tagConstKey] = tagProp
  }
  return requestOfCallCancel
}

/**
 * @description: 创建一个取消请求Axios实例
 * @param {Record<string,any>} [wrapDefaultConfig] - 同 axiosConfig 包裹函数默认参数配置
 * @param {object} [requestInstance] 默认请求实例
 * @return {(config:Record<string,any>)=>Promise<any>}
 */
export function creatCancelRequest(wrapDefaultConfig, requestInstance = request) {
  return cancelRequestWrap(requestInstance, wrapDefaultConfig)
}

export const createCancelRequest = creatCancelRequest

/**
 * @description: 添加路由实例 beforeEach 方法
 * @param {object} router - 路由实例
 * @return {void}
 */
export const routerProxyByCancelRequest = (router) => {
  if (!router) { return }
  router.beforeEach((to, from, next) => {
    if (from && from.path) {
      // 清除之前的路由的request
      cancelTagRequest(from.path)
    }
    next()
  })
}

// 默认取消请求实例
export const defaultCancelRequest = creatCancelRequest()

