import { useStoreModule } from './util'
export { default as useAppStore } from '@pacvue/frame/frameStore'
import * as modules from './modules'
export const { useUserStore,
  useHomeStore,
  useCommonStore,
  useCommerceStore,
  useDriverStore,
  useDriverAlertStore,
  useDriverJBPStore,
  useCustomServiceStore: useCsStore,
  useAsinDetailStore,
  usePeriscopeStore,
  useShareStore,
  useMyReportStore,
  useTargetStore,
  useSalesStore,
  useRealTimeSalesStore,
  useAlertsMainStore,
  useSalesAsinTimeStore,
  useSalesTargetStore,
  usePromotionStore,
  useProfitabilityStore,
  useInventoryStore
} = useStoreModule(modules)
