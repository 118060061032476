import { attempt, debounce } from 'lodash-es'
import { watch } from 'vue'
import mitt from 'mitt'

const setLocalStorage = ({ key, value }) => {
  localStorage.setItem(key, value)
}

// 考虑防抖优化的 localStorage 性能的操作
const createSetLocalStorage = () => {
  const setLocalStorageDebounced = debounce(({ key, value }) => {
    localStorage.setItem(key, value)
  }, 60)

  const setLocalStorage = ({ key, value }) => {
    // 防止由于防抖, 页面watch的回调没拿到localStorage的值
    if (!localStorage.getItem(key)) {
      localStorage.setItem(key, value)
    }
    setLocalStorageDebounced({ key, value })
  }

  return setLocalStorage
}

export const userStorePersistPlugin = ({
  PiniaPersistList
}) => {
  // 存放监听执行 setLocalStorage 的副作用函数
  const observerMap = {}
  // 监听userStore的初始赋值
  let userStateObserver = null
  const eventBus = mitt()
  return ({ store, options }) => {
    const defaultPersistOptions = {
      // 是否独立存储
      isModuleStorage: false,
      // 是否登出清除缓存
      isLogoutClearStorage: true,
      // 在登出监听的字段
      logoutWatchFields: []
    }
    const persist = options.persist || {}
    const platformName = store.platformName
    const moduleName = store.$id
    const CompressedPrefix = persist.CompressedPrefix || '__COMPRESSED__'
    // 创建 store key
    /* 登出是否清除的规则：
      watchFields?: string[] // 监听字段(默认[]: 不监听)
      isLogoutClearStorage?: boolean // 是否登出清除缓存(默认true: 清除)
      logoutWatchFields?: string[]  // 在登出监听的字段(默认[]: 清除所有)

      1. isLogoutClearStorage === true // 清除模式
        logoutWatchFields === undefined // 默认清除所有 watchFields 字段
        logoutWatchFields === string[] // 保留 logoutWatchFields 字段

      2. isLogoutClearStorage === false // 保留模式
        logoutWatchFields === undefined // 默认保留所有 watchFields 字段
        logoutWatchFields === string[] 	// 清除 logoutWatchFields 字段
    */
    const mergedPersistOptions = { ...defaultPersistOptions, ...persist }
    const { isLogoutClearStorage = true, logoutWatchFields = [] } = mergedPersistOptions

    const createStorageKey = ({ platformName = '', fieldName = '' }) => {
      // 是否退出登录还储存
      const key_storage = attempt(() => {
        // 在 PiniaPersistList 中的字段直接存储
        if (PiniaPersistList.includes(fieldName)) {
          return 'storage'
        }
        // 默认清除所有 watchFields 字段
        if (isLogoutClearStorage) {
          // 保留 logoutWatchFields 字段
          if (logoutWatchFields.includes(fieldName)) {
            return 'storage'
          } else {
            return ''
          }
        // 默认保留所有 watchFields 字段
        } else {
          // 清除 logoutWatchFields 字段
          if (logoutWatchFields.includes(fieldName)) {
            return ''
          } else {
            return 'storage'
          }
        }
      })
      const key_userId = localStorage.getItem('uid')
      const key_platformName = persist.isCommon ? '' : platformName
      const key_moduleName = mergedPersistOptions.isModuleStorage ? moduleName : ''
      const storeKey = attempt(() => {
        const splitKeyList = [
          key_storage,
          key_userId,
          key_platformName,
          key_moduleName,
          fieldName
        ]
        const splitKeyStr = splitKeyList.filter(key => key).join('_')
        return splitKeyStr
      })
      return storeKey
    }

    // 读取浏览器持久化缓存并设置piniaStore
    const setPinia = (fieldName) => {
      const storeKey = createStorageKey({ platformName, fieldName })
      const storeStr = localStorage.getItem(storeKey)
      if (storeStr) {
        const tempString = (persist.compressKeyList || []).includes(fieldName) && storeStr.startsWith(CompressedPrefix) ? persist.decompress(storeStr.slice(CompressedPrefix.length)) : storeStr
        store[fieldName] = JSON.parse(tempString)
      }
      return {
        storeKey,
        storeStr
      }
    }

    const watchAndSetStorage = (fieldName) => {
      const { storeKey } = setPinia(fieldName)
      // 监听字段变化
      const unsubscribeOb = watch(() => store[fieldName], (newValue, oldValue) => {
        const tempString = JSON.stringify(newValue)
        setLocalStorage({ key: storeKey, value: (persist.compressKeyList || []).includes(fieldName) ? `${CompressedPrefix}${persist.compress(tempString)}` : tempString })
      }, {
        deep: true
      })
      observerMap[store.$id].push(unsubscribeOb)
    }

    // 拿到正确的、退出登录保留的 persist 持久化缓存
    // 确定拿到uid后再调这个方法, 给需要uid的字段赋值和监听
    const handleUpdateStoreByUserInit = () => {
      // 退出登录持久化字段的赋值和监听
      needUpdateList.forEach((fieldName) => {
        watchAndSetStorage(fieldName)
      })
    }

    // 监听字段
    let watchFields = []
    let needUpdateList = []

    if (persist.enabled) {
      // 开启持久化
      watchFields = persist.watchFields || []

      // 获取需要更新的、退出登录保留的持久化字段
      needUpdateList = attempt(() => {
        const tempList = []
        // 全部清除, 保留 logoutWatchFields 字段
        if (isLogoutClearStorage) {
          watchFields.forEach((fieldName) => {
            if (logoutWatchFields.includes(fieldName)) {
              tempList.push(fieldName)
            }
          })
        // 全部保留, 清除 logoutWatchFields 字段
        } else {
          watchFields.forEach((fieldName) => {
            if (!logoutWatchFields.includes(fieldName)) {
              tempList.push(fieldName)
            }
          })
        }
        return tempList
      })

      if (store.$id === 'frameStore') {
        // frameStore中的值，在都需要在uid有了之后再赋值和监听
        needUpdateList.push(...watchFields)
      }

      observerMap[store.$id] = attempt(() => {
        const existObserver = [...(observerMap[store.$id] || [])]
        while (existObserver.length) {
          existObserver.pop()()
        }
        return existObserver
      })

      const uid = localStorage.getItem('uid')

      // 普通字段的赋值和监听
      watchFields.forEach((fieldName) => {
        // 这里不一定拿到uid, 保持原先逻辑, 先给不需要uid的字段赋值和监听
        if (!needUpdateList.includes(fieldName)) {
          watchAndSetStorage(fieldName)
        }
        // 如果有uid，比如用户刷新页面的时候, 直接赋值和监听退出登录保留的持久化字段
        if (uid) {
          handleUpdateStoreByUserInit()
        }
      })
      // 重新登录的时候，可能还没有uid
      // 添加事件总线回调, uid 变化时重新给piniaStore赋值, 并监听退出登录保留的持久化字段
      eventBus.off('userStateInit', handleUpdateStoreByUserInit)
      eventBus.on('userStateInit', handleUpdateStoreByUserInit)
    }

    // 由于store初始化时，uid 不一定有值，所以：
    // 如果是 userStore，监听uid变化, 更新storeKey, 重新给piniaStore赋值
    if (store.$id === 'user') {
      // 防止本地热更新, 重复监听
      userStateObserver?.()
      userStateObserver = watch(() => store.user, (newValue, oldValue) => {
        if (newValue && !oldValue) {
          // 派发更新事件
          eventBus.emit('userStateInit')
        }
      }, {
        deep: true,
        immediate: true
      })
    }
  }
}
