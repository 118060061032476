
const state = () => ({
  calendarCurrentViewType: '',
  sellerStore: {
    tabValue: '',
    filterFormByPromotion: {},
    filterFormByProduct: {}
  },
  vendorStore: {
    tabValue: '',
    filterFormByPromotion: {},
    filterFormByProduct: {}
  }
})

const actions = {

}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    isLogoutClearStorage: false,
    watchFields: ['calendarCurrentViewType', 'sellerStore', 'vendorStore']
  }
}
