/**
 * @description: 根据查询条件获取最近的祖先元素
 * @param {HTMLElement} el
 * @param {string} selector
 * @return {HTMLElement|null}
 */
export const getEleClosest = (el, selector) => {
  const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector
  while (el) {
    if (matchesSelector.call(el, selector)) {
      break
    }
    el = el.parentElement
  }
  return el
}
/**
 * @description: 获取元素是否具有匹配的祖先元素
 * @param {HTMLElement} el
 * @param {string} selector
 * @return {boolean}
 */
export const hasClosestParentEle = (el, selector) => {
  const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector
  let hasParent = false
  while (el) {
    if (matchesSelector.call(el, selector)) {
      hasParent = true
      break
    }
    el = el.parentElement
  }
  return hasParent
}
