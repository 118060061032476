import moneySymbol from '@commerceCommon/const/CurrencySymbol'
import { useUserStore, useCommonStore } from '@#/store'
import { useRoute } from 'vue-router'
import { v4 as uuidV4 } from 'uuid'
import CurrencyName from '@commerceCommon/const/CurrencyName'
import { dayjs } from '@pacvue/element-plus'

/**
 * 为空判断
 * @returns {boolean}
 */
export function isEmpty(...args) {
  const len = arguments.length
  if (len === 0) {
    return true
  }
  for (let i = 0; i < len; i++) {
    const a = args[i]
    if (typeof a === 'undefined' || a == null || (typeof a === 'string' && a.trim() === '')) {
      return true
    } else if (Object.prototype.toString.call(a) === '[object Array]') {
      if (a.length === 0) {
        return true
      }
    } else if (Object.prototype.toString.call(a) === '[object Object]') {
      if (Object.keys(a).length === 0) {
        return true
      }
    }
  }
  return false
}

export function isFunction(fn) {
  return typeof fn === 'function' && typeof fn.nodeType !== 'number'
}

export function promiseTo(promise, errorExt) {
  return promise
    .then((data) => [data, null])
    .catch((err) => {
      if (errorExt) {
        const parsedError = Object.assign({}, err, errorExt)
        return [undefined, parsedError]
      }
      return [undefined, err]
    })
}

export const toThousands = (a = 0, fractionDigits = 1) => {
  if (a === null) {
    return '--'
  }
  let abs = ''
  let xiaoshu = '' // 用来记录参数小数数值包括小数点
  let zhengshu = '' // 用来记录参数录整数数值
  if (a < 0) {
    a = Math.abs(a)
    abs = '-'
  }
  if (a < 1000) {
    // 当参数小于1000的时候直接返回参数
    return abs + getFixedNumber(a, fractionDigits)
  } else {
    const t = getFixedNumber(a, fractionDigits) // 将整数转换成字符串
    if (t.indexOf('.') > 0) {
      // 如果参数存在小数，则记录小数部分与整数部分
      const index = t.indexOf('.')
      xiaoshu = t.slice(index, t.length)
      zhengshu = t.slice(0, index)
    } else {
      // 否则整数部分的值就等于参数的字符类型
      zhengshu = t
    }
    const num = parseInt(zhengshu.length / 3) // 判断需要插入千位分割符的个数

    // 将整数1234567890部分拆分为2部分，变量head:1   变量body:123456789
    let head = zhengshu.slice(0, zhengshu.length - num * 3)
    if (head.length > 0) {
      // 如果head存在，则在head后面加个千位分隔符，
      head += ','
    }
    let body = zhengshu.slice(zhengshu.length - num * 3, zhengshu.length)

    // 将body字符串123456789转换成一个字符数组arr2 = ['123','456','789']
    const arr2 = []
    for (let i = 0; i < num; i++) {
      arr2.push(body.slice(i * 3, i * 3 + 3))
    }
    body = arr2.join(',') // 将数组arr2通过join(',')   方法，拼接成一个以逗号为间隔的字符串

    zhengshu = head + body // 拼接整数部分
    const result = zhengshu + xiaoshu // 最后拼接整数和小数部分
    return abs + result // 返回结果
  }
}

export const toThousandsx = (t, state = false, fixNumber = 0) => {
  if (t === null) {
    return '--'
  }
  if (state) {
    return toThousands(t, fixNumber)
  }
  t = Number(t)
  const prefix = t < 0 ? '-' : ''
  t = Math.abs(t)
  if (t > 1000000) {
    return `${prefix}${toThousands(t / 1000000)}M`
  } else if (t > 10000) {
    return `${prefix}${toThousands(t / 1000)}K`
  } else {
    return `${prefix}${toThousands(t, fixNumber)}`
  }
}

export function formatPercentOfCarry(value, carryNumber = 0, fixNumber = 1, unit = '%') {
  if (value === null || value === undefined) {
    return '--'
  }/*  else if (value === 0) {
    return '0.00%'
  } */
  value = Number(value)
  return `${getFixedNumber(value * 10 ** carryNumber, fixNumber)}${unit}`
}
/**
 * 数字格式化 四舍五入
 * @param {Number} num
 * @param {Number} fixNumber 保留小数位数
 * @returns {string}
 */
export const formatFixed = (num, fixNumber) => {
  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: fixNumber,
    maximumFractionDigits: fixNumber
  }).format(num)
}
/**
 * 数字四舍五入
 * @param num
 * @param fractionDigits 保留小数位数
 * @returns {string}
 */
export const getFixedNumber = (num, fractionDigits = 2) => {
  if (isEmpty(num)) {
    return ''
  }
  num = parseFloat(num)
  const absNum = Math.abs(num)
  const prefix = num < 0 ? '-' : ''
  return prefix + (Math.round((absNum + Number.EPSILON) * 10 ** fractionDigits) / 10 ** fractionDigits).toFixed(fractionDigits)
}

/**
 * 金额格式化，四舍五入
 * 2023-09-26变更：金额格式化负号放到货币符号之前
 * @param money
 * @param symbol
 * @param isFullNumber
 * @returns {string}
 */
export const formatMoneyRound = (money = 0, symbol = '$', isFullNumber = false) => {
  const _money = Number(money)
  const absMoney = Math.abs(_money)
  const isNegative = _money < 0
  if (symbol && symbol === '￥') {
    return `${isNegative ? '-' : ''}${symbol}${toThousands(Math.round(absMoney))}`
  }
  if (isFullNumber) {
    return `${isNegative ? '-' : ''}${symbol}${toThousands(absMoney, 2)}`
  }
  if (absMoney >= 1000000) {
    return `${isNegative ? '-' : ''}${symbol}${toThousands(absMoney / 1000000, 2)}M`
  } else if (absMoney >= 10000) {
    return `${isNegative ? '-' : ''}${symbol}${toThousands(absMoney / 1000, 2)}K`
  } else {
    return `${isNegative ? '-' : ''}${symbol}${toThousands(absMoney, 2)}`
  }
}

export function getColFormatValue(colValue, colType, countrySymbol, formatConfig) {
  const formatConfigAss = Object.assign(
    {
      carryNumber: 0,
      fixNumber: 1,
      // 指定货币符号
      moneySymbol: undefined,
      countrySymbol,
      isFullNumber: false
    },
    formatConfig
  )
  if (colValue === '' || colValue === null || colValue === undefined) {
    return '--'
  }
  if (colType === 'number') {
    return toThousandsx(colValue, formatConfigAss.isFullNumber || false)
  } else if (colType === 'float') {
    return toThousandsx(colValue, formatConfigAss.isFullNumber || false, formatConfigAss.fixNumber)
  } else if (colType === 'percent') {
    return formatPercentOfCarry(colValue, formatConfigAss.carryNumber, formatConfigAss.fixNumber)
  } else if (colType === 'money') {
    const userStore = useUserStore()
    const _countrySymbol = formatConfigAss.countrySymbol || userStore?.user?.defaultCurrency || 'US'
    const _moneySymbol = formatConfigAss.moneySymbol || moneySymbol[_countrySymbol]
    return formatMoneyRound(colValue, _moneySymbol, formatConfigAss.isFullNumber || false)
  } else if (colType === 'bps') {
    return formatPercentOfCarry(colValue, formatConfigAss.carryNumber, formatConfigAss.fixNumber, 'bps')
  } else {
    return colValue || '--'
  }
}

export const replaceOldImage = (data) => {
  if (data === null || data === 'null' || data === '' || !data) {
    return 'https://pacvue-public-doc.s3.us-west-2.amazonaws.com/oss/pacvue-cdn/vue3/no-img.svg'
  }
  return data.replace('http://ecx.images-amazon.com', 'https://m.media-amazon.com')
}

export const marketAddress = (val, asin, platform) => {
  // 页面channel跳转拼接路径
  let city = ''
  let http
  let asinStr
  if (!platform) {
    const commonStore = useCommonStore()
    platform = commonStore.commercePlatform || 'amazon'
  }
  switch (platform) {
    case 'amazon':
      http = 'https://www.amazon.'
      asinStr = `/dp/${asin}`
      break
    case 'walmart':
      http = 'https://www.walmart.'
      asinStr = `/ip/${asin}`
  }
  if (!val.includes(' ')) {
    city = val
  } else {
    city = val.split(/[ ]+/)[1]
  }
  switch (city) {
    case 'AU':
      return `${http}com.au${asinStr}`
    case 'BR':
      return `${http}com.br${asinStr}`
    case 'CA':
      return `${http}ca${asinStr}`
    case 'CN':
      return `${http}cn${asinStr}`
    case 'FR':
      return `${http}fr${asinStr}`
    case 'DE':
      return `${http}de${asinStr}`
    case 'IN':
      return `${http}in${asinStr}`
    case 'IT':
      return `${http}it${asinStr}`
    case 'JP':
      return `${http}co.jp${asinStr}`
    case 'MX':
      return `${http}com.mx${asinStr}`
    case 'NL':
      return `${http}nl${asinStr}`
    case 'ES':
      return `${http}es${asinStr}`
    case 'TR':
      return `${http}com.tr${asinStr}`
    case 'UK':
      return `${http}co.uk${asinStr}`
    case 'US':
      return `${http}com${asinStr}`
    case 'AE':
      return `${http}ae${asinStr}`
    case 'SA':
      return `${http}sa${asinStr}`
    case 'PL':
      return `${http}pl${asinStr}`
    case 'EG':
      return `${http}eg${asinStr}`
    case 'SG':
      return `${http}sg${asinStr}`
    case 'SE':
      return `${http}se${asinStr}`
    case 'BE':
      return `${http}com.be${asinStr}`
    default:
      return `${http}com${asinStr}`
  }
}

export const getCountry = () => {
  const countryList = Object.keys(moneySymbol).filter(v => v !== 'CN')
  const countryObj = {}
  countryList.forEach((key) => {
    countryObj[key] = `https://pacvue-public-doc.s3.us-west-2.amazonaws.com/oss/pacvue-cdn/country/${key}.png`
  })
  return countryObj
}

const getTranslated = (key) => {
  const tMap = {
    'US Dollar': $t('CommerceCommon.Currency.amskey1916'),
    'Australian Dollar': $t('CommerceCommon.Currency.amskey1917'),
    'Canadian Dollar': $t('CommerceCommon.Currency.amskey1918'),
    'British Pound': $t('CommerceCommon.Currency.amskey1919'),
    'Japanese Yen': $t('CommerceCommon.Currency.amskey1920'),
    'Mexican Peso': $t('CommerceCommon.Currency.amskey1921'),
    'Brazil Real': $t('CommerceCommon.Currency.amskey1922'),
    'Indian Rupee': $t('CommerceCommon.Currency.amskey1923'),
    'Saudi Riyal': $t('CommerceCommon.Currency.amskey1924'),
    'Emirati Dirham': $t('CommerceCommon.Currency.amskey1925'),
    'Singapore Dollar': $t('CommerceCommon.Currency.amskey1926'),
    'Swedish krona': $t('CommerceCommon.Currency.amskey1927'),
    'Turkey Liras': $t('CommerceCommon.Currency.amskey1928'),
    'Egyptian Pound': $t('CommerceCommon.Currency.amskey1929'),
    'Poland zlotych': $t('CommerceCommon.Currency.amskey1930'),
    'Euro': $t('CommerceCommon.Currency.amskey1931')
  }
  return tMap[key]
}

/**
 * 返回一个包含国家名称和对应货币名称的数组。
 *
 * @return {Array} 一个包含对象的数组，对象具有 `value` 和 `label` 属性，分别表示货币名称和国家名称。
 */
export const getCountryName = () => Object.keys(CurrencyName).map(item => ({ value: item, label: getTranslated(CurrencyName[item]) }))

/**
 * 获取上升下降箭头svg名称
 * @param {Number} val 数值
 * @param {Number} type 类型
 * 1、中性值上升或下降都使用PacvueIconWidgetArrowRight
 * 2、下降是良性值，下降使用PacvueIconArrowUp，上升使用PacvueIconArrowDown
 * 3、上升为良性值，上升使用PacvueIconArrowUp，下降使用PacvueIconArrowDown
 * @returns {string} svgName
 *
 * PAC-7073 只根据数值判断 大于0 上升  等于0 水平 小于0 下降
 */
export const getIcon = (val, type = 3, fractionDigits = 1) => {
  if (isEmpty(val)) {
    return ''
  }
  if (val === 0) {
    return 'PacvueIconWidgetArrowRight'
  }
  const value = Number(getFixedNumber(val, fractionDigits))
  // if (value > 0) {
  //   return type === 3 ? 'PacvueIconArrowUp' : type === 2 ? 'PacvueIconArrowDown' : 'PacvueIconWidgetArrowRight'
  // } else if (value < 0) {
  //   return type === 3 ? 'PacvueIconArrowDown' : type === 2 ? 'PacvueIconArrowUp' : 'PacvueIconWidgetArrowRight'
  // } else if (value === 0) {
  //   return 'PacvueIconWidgetArrowRight'
  // }
  return value > 0 ? 'PacvueIconArrowUp' : value === 0 ? 'PacvueIconWidgetArrowRight' : 'PacvueIconArrowDown'
}
/**
 *
 * @param {Number} val 数值
 * @param {Number} type 类型
 * 获取chart数值条颜色
 * 1、中性值上升或下降都使用灰色。
 * 2、下降是良性的值，下降全部用绿色。上升0-50%之间用黄色，51%以上用红色。
 * 3、上升为良性的值，上升全部用绿色。下降0-50%之间用黄色，51%以上用红色。
 */
export const getColor = (val, type = 3, fractionDigits = 1) => {
  const gray = '#b2b2b2'
  const red = '#ea5455'
  const green = '#28c76f'
  const yellow = '#ff9f43'
  // 中性值
  if (type === 1) {
    return gray
  }
  if (val === null || val === 0) {
    return gray
  }
  const value = Number(getFixedNumber(val, fractionDigits))
  // 下降是良性
  if (type === 2) {
    if (!value) {
      return gray
    } else if (value < 0) {
      return green
    } else if (value <= 50) {
      return yellow
    } else {
      return red
    }
  }
  // 上升为良性
  if (type === 3) {
    if (!value) {
      return gray
    } else if (value > 0) {
      return green
    } else if (value >= -50) {
      return yellow
    } else {
      return red
    }
  }
}

export const firstUpperString = (val) => {
  if (!val) {
    return ''
  }
  return val[0].toUpperCase() + val.substr(1)
}

/**
 * 判断是否选中文本
 * @returns {string}
 */
export const getWindowSelected = () => {
  if (window.getSelection) {
    const selection = window.getSelection()
    return selection.type === 'Range' && selection.anchorNode?.nodeType === Node.TEXT_NODE && selection.focusNode?.nodeType === Node.TEXT_NODE ? selection.toString() : ''
  } else if (document.getSelection()) {
    return document.getSelection().toString()
  } else {
    const selection = document.selection && document.selection.createRange()
    if (selection.text) {
      return selection.text.toString()
    }
    return ''
  }
}

/**
 * 将返回的对象数据转换成PacvueChart所需的列表数据
 * @param data 返回的对象数据
 * @param xAxisName 返回对象数据中x轴属性名
 * @param propList 指标列表，对象中一般包括显示名称title、属性名prop、颜色color、格式化类型formatType
 * @param newAxisName 新的x轴属性名
 * @returns {*}
 */
export const convertObjectDataToListDataForChart = (data, xAxisName, propList) => {
  const xAxisList = data[xAxisName] || []
  return xAxisList.map((v, i) => {
    const obj = {}
    propList.forEach(vv => {
      obj[vv.prop] = parseFloat(data[vv.prop][i])
    })
    return {
      [xAxisName]: v,
      ...obj
    }
  })
}

/**
 * 把数字格式化相应的类型
 * @param num 数值
 * @param type 类型
 * @param symbol 钱符号
 * @param originalNumber type 为钱和数字时 true保留原本数值完整 type 为百分比时 false保留原本数值
 * @param carryNumber 百分比 需要乘以数
 * @param fixNumber 百分比 保留小数位
 * @returns 字符串
 */
export function formatData(
  num,
  type = '',
  symbol = undefined,
  isFullNumber = false,
  carryNumber = 0,
  fixNumber = 1
) {
  const lowerType = type ? type.toLowerCase() : ''
  if (isNaN(num)) {
    return '--'
  } else {
    if (lowerType) {
      if (
        [
          'po fill rate',
          'buybox ownership trend',
          'buybox ownership',
          'net ppm%',
          'gm%',
          'gross margin',
          'margin',
          'acos',
          'ad cvr',
          'total acos',
          'ctr',
          'ad ctr',
          'cvr',
          'conversion rate',
          'gpm%',
          'net ppp%',
          'ad sales%',
          'ad spend%',
          'not delivered due to oos',
          'sns % of total ordered units',
          'sns % of total ordered revenue',
          'sns adjusted conversion rate',
          'sns adjusted t-acos',
          'percent'
        ].includes(lowerType)
      ) {
        if (num === null || num === undefined) {
          return '--'
        } else if (num === 0) {
          return '0.0%'
        }
        num = Number(num)
        return getColFormatValue(num, 'percent', null, {
          carryNumber,
          fixNumber: 1
        })
      } else if (
        [
          'ordered units',
          'units',
          'weeks of cover',
          'ad click',
          'ad clicks',
          'number',
          'ad units',
          'ad orders',
          'impressions',
          'glance view',
          'glance views',
          'shipped units',
          'sellable on hand units',
          'reviews',
          'content score',
          'ratings',
          'session',
          'page view',
          'sale units',
          'sales units',
          'customer returns',
          'subscriptions',
          'not delivered units due to oos',
          'shipped sns units',
          'sns ordered units',
          'non-sns ordered units',
          'orders',
          'Shipped SnS Units',
          'Total Ordered Units',
          'Est.SnS Ordered Units',
          'NON-SnS Ordered Units',
          'Sale Units',
          'Shipped SnS Units',
          'dsp sales units',
          'Non-SnS Sale Units',
          'nonSnsOrderedUnits',
          'snsOrderedUnits',
          'totalOrderedUnits',
          'shippedSnsUnits'
        ]
          .map((e) => e.toLocaleLowerCase())
          .includes(lowerType)
      ) {
        if (
          ['content score', 'ratings']
            .map((e) => e.toLocaleLowerCase())
            .includes(lowerType)
        ) {
          if (num === null || num === undefined) {
            return '--'
          }
          return num.toFixed(1)
          // isFullNumber = true
        }
        return getColFormatValue(num, 'number', null, {
          carryNumber,
          isFullNumber,
          fixNumber
        })
      } else {
        return getColFormatValue(num, 'money', null, {
          carryNumber,
          isFullNumber,
          fixNumber,
          moneySymbol: symbol
        })
      }
    } else {
      return getColFormatValue(num, 'money', null, {
        carryNumber,
        isFullNumber,
        fixNumber,
        moneySymbol: symbol
      })
    }
  }
}
// 数字转为百分比并保留特定位数
export const pacvuePercent = (v, operator = false, toFixed = 2) => {
  if (v === null || v === undefined || isNaN(v)) {
    return '--'
  } else if (operator) {
    if (v === 0) {
      return '0%'
    } else
    if (v > 0) {
      return '+ ' + Number(Math.abs(v).toFixed(toFixed)) + '%'
    } else if (v < 0) {
      return '- ' + Number(Math.abs(v).toFixed(toFixed)) + '%'
    }
  } else {
    return Number(Math.abs(v).toFixed(toFixed)) + '%'
  }
}
export const handleSellerPage = (val, seller) => {
  let city = ''
  const http = 'https://www.amazon.'
  const sellerStr = '/sp?seller=' + seller
  if (!val.includes(' ')) {
    city = val
  } else {
    city = val.split(/[ ]+/)[1]
  }
  switch (city) {
    case 'AU':
      return http + 'com.au' + sellerStr
    case 'BR':
      return http + 'com.br' + sellerStr
    case 'CA':
      return http + 'ca' + sellerStr
    case 'CN':
      return http + 'cn' + sellerStr
    case 'FR':
      return http + 'fr' + sellerStr
    case 'DE':
      return http + 'de' + sellerStr
    case 'IN':
      return http + 'in' + sellerStr
    case 'IT':
      return http + 'it' + sellerStr
    case 'JP':
      return http + 'co.jp' + sellerStr
    case 'MX':
      return http + 'com.mx' + sellerStr
    case 'NL':
      return http + 'nl' + sellerStr
    case 'ES':
      return http + 'es' + sellerStr
    case 'TR':
      return http + 'com.tr' + sellerStr
    case 'UK':
      return http + 'co.uk' + sellerStr
    case 'US':
      return http + 'com' + sellerStr
    case 'AE':
      return http + 'ae' + sellerStr
    case 'SA':
      return http + 'sa' + sellerStr
    default:
      return http + 'com' + sellerStr
  }
}

export const getTreeDataValue = (key, filterConfig = [], dataList = []) => {
  return (filterConfig.find(v => v.key === key)?.itemList || []).filter(v => dataList.includes(v.id))
}

/**
 * 获取节点路径
 * @param list 数据列表
 * @param id 唯一标识
 * @param prop 返回节点的属性，不传返回整个节点
 * @returns {*[]} 返回节点路径数组
 */
export const getTreeNodePath = (list = [], id, prop) => {
  let result = []
  const path = []
  function traverse(list, id) {
    if (list.length === 0) {
      return
    }
    for (const item of list) {
      path.push(prop ? item[prop] : item)
      if (item.id === id) {
        result = [...path]
        return
      }
      if (item.children && item.children.length > 0) {
        traverse(item.children, id)
      }
      path.pop()
    }
  }
  traverse(list, id)
  return result
}

/**
 * 通过叶子节点id集合获取相关路径的树结构
 * @param dataList 数据列表
 * @param idList id集合
 * @returns {*[]}
 */
export const getTreeLevelDataValue = (dataList = [], idList = []) => {
  if (isEmpty(dataList)) {
    return []
  }
  const tempList = JSON.parse(JSON.stringify(dataList))
  const pathList = idList.map(v => getTreeNodePath(tempList, v, null))
  /**
   * 从右往左，合并到根节点
   */
  function getRootObj(path) {
    while (path.length > 0) {
      const lastNode = path.pop()
      if (path.length === 0) {
        return lastNode
      }
      path[path.length - 1].totalLength = path[path.length - 1].children.length
      path[path.length - 1].children = [lastNode]
    }
  }
  function setSameRoot(list, path) {
    let currentNode = list
    const tempPath = [...path]
    for (const vPath of path) {
      const index = currentNode.children.findIndex(item => item.id === vPath.id)
      if (index > -1) {
        tempPath.splice(0, 1)
        currentNode = currentNode.children[index]
      } else {
        break
      }
    }
    // 将剩下不相同的部分生成新节点
    const lastRoot = getRootObj(tempPath)
    if (!currentNode.children) {
      currentNode.children = []
    }
    currentNode.children.push(lastRoot)
  }
  const list = []
  pathList.forEach(path => {
    const index = list.findIndex(v => v.id === path[0].id)
    if (index > -1) {
      // 找到相同根节点，则设置节点
      path.splice(0, 1)
      setSameRoot(list[index], path)
    } else {
      // 找不到相同根节点，则直接添加
      list.push(getRootObj(path))
    }
  })
  // 添加select属性
  function handleSelect(parentNode) {
    parentNode.children.forEach((node, i) => {
      let selectCount = 0
      if (node.children && node.children.length > 0) {
        handleSelect(node)
        if (node.select) {
          selectCount++
        }
      } else {
        selectCount++
        node.select = true
      }
      if (i === parentNode.children.length - 1) {
        if (parentNode.totalLength === selectCount) {
          parentNode.select = true
          parentNode.children = []
        } else {
          parentNode.select = false
        }
        delete parentNode.totalLength
      }
    })
  }
  list.forEach(v => {
    if (v.children) {
      handleSelect(v)
    } else {
      v.select = true
    }
  })
  return list
}

export function getWidthOfText(label) {
  if (!label) {
    return 0
  }
  let canvas = document.createElement('canvas')
  let context = canvas.getContext('2d')
  context.font = '900 12px/18px normal Inter,Helvetica,Arial'
  const labelNodeList = label.split('\n')
  const labelNodeWidthList = labelNodeList.map((labelNode) => {
    const labelNodeWidth = context.measureText(labelNode).width
    return labelNodeWidth
  })
  canvas = null
  context = null
  return Math.max(...labelNodeWidthList)
}

// 获取当前时区
// export function getUniqueTimeZone() {
//   return -(new Date().getTimezoneOffset() / 60)
// }

/**
 * 获取当前时区，不考虑夏令时（为了和VC后端对上PAC-28193）
 * @returns {number}
 */
export function getUniqueTimeZone() {
  const januaryDate = dayjs().utc().month(0).date(1).hour(0).minute(0).second(0)
  const offsetInMinutes = januaryDate.tz().utcOffset()
  return offsetInMinutes / 60
}

// 导出excel
export function downLoad(res, name) {
  if (res.type && res.type.includes('json')) {
    const reader = new FileReader()
    reader.readAsText(res)
    reader.onload = (e) => {
      const err = JSON.parse(e.target.result)
      Vue.prototype.$alert('<p style="font-size:12px">' + err.msg + '</p>', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: 'OK',
        type: 'warning'
      })
    }
    return false
  }
  const blob = new Blob([res.data])// 老项目的这个接口特殊
  const downloadElement = document.createElement('a')
  const href = window.URL.createObjectURL(blob) // 创建下载的链接
  downloadElement.href = href
  downloadElement.download = name // 下载后文件名
  document.body.appendChild(downloadElement)
  downloadElement.click() // 点击下载
  document.body.removeChild(downloadElement) // 下载完成移除元素
  window.URL.revokeObjectURL(href) // 释放掉blob对象
  return true
}
export const formatSalesMoney = (
  money = 0,
  num = '$',
  state = false,
  decimal = false
) => {
  const type = num
  if (money === null) {
    return '--'
  }
  if (state) {
    if (decimal) {
      return type + toThousands(money.toFixed(2))
    }
    return type + toThousands(money.toFixed(2))
  }
  if (money === 0) {
    return type + money
  }
  if (num && num === '￥') {
    return type + toThousands(Math.round(Number(money)))
  }
  if (money < 0) {
    money = Math.abs(money)
    if (money >= 1000000) {
      return type + '-' + toThousands((money / 1000000).toFixed(1)) + 'M'
    } else if (money > 10000) {
      return type + '-' + toThousands(Number(money / 1000).toFixed(1)) + 'K'
    } else if (money > 10) {
      return type + '-' + toThousands(Number(money).toFixed(0))
    } else if (money > 0.1) {
      return type + '-' + toThousands(Number(money).toFixed(1))
    } else {
      return type + '-' + toThousands(Number(money).toFixed(2))
    }
  } else {
    if (money >= 1000000) {
      return type + toThousands((money / 1000000).toFixed(1)) + 'M'
    } else if (money > 10000) {
      return type + toThousands(Number(money / 1000).toFixed(1)) + 'K'
    } else if (money > 10) {
      return type + toThousands(Number(money).toFixed(0))
    } else if (money > 0.1) {
      return type + toThousands(Number(money).toFixed(1))
    } else {
      return type + toThousands(Number(money).toFixed(2))
    }
  }
}
export const RCode = {
  ALLOCATED: 'AL',
  Unknown: 'UN',
  BASIC_REPLENISHMENT: 'BR',
  IN_SEASON: 'IS',
  LIMITED_REPLENISHMENT: 'LR',
  MANUFACTURER_OUT_OF_STOCK: 'OS',
  NEW_PRODUCT: 'NP',
  NON_REPLENISHABLE: 'NR',
  NON_STOCKUPABLE: 'NS',
  OBSOLETE: 'OB',
  PLANNED_REPLENISHMENT: 'PR'
}
export const marketCodeBudget = (val) => {
  // 返回根据表channel切换不同的货币单位
  let city = ''
  if (!val.includes(' ')) {
    city = val
  } else {
    city = val.split(/[ ]+/)[1]
  }
  return moneySymbol[city]
}
export const uniq = (array) => {
  return Array.from(new Set(array))
  // var temp = [] // 一个新的临时数组
  // for (var i = 0; i < array.length; i++) {
  //   if (i === 0) {
  //     temp.push(array[i])
  //   } else {
  //     if (temp?.includes(array[i])) {
  //       temp.push(array[i])
  //     }
  //   }
  // }
  // return temp
}

/**
 * 转换成所需的list数据结构
 *
 * @param {*} data
 * @return {*}
 */
export function transToListData(data) {
  const kv = []
  data?.xAxis?.forEach((x, i) => {
    const nO = {}
    nO.Date = x
    for (const k in data) {
      if (k !== 'generatedDate') {
        nO[k] = data[k][i]
      }
    }
    kv.push(nO)
  })
  return kv
}

export function ContentScoreColor(num) {
  if (num === null) {
    return ''
  }
  if (num < 50) {
    return '#ff6c5c'
  } else if (num >= 50 && num < 75) {
    return '#ffb03e'
  } else if (num >= 75) {
    return '#52c41b'
  }
}
// AsinDetail content通过类型获取文字颜色
export const getAsinDetailTextColorByType = (val, commerceType) => {
  // console.log(commerceType)
  // 灰色
  if (!val) {
    return '#DDDDDD'
  }
  // 红灯
  if (val === 1) {
    return '#FF6C5C'
  }
  // 黄灯
  if (val === 2) {
    return '#FFB03E'
  }
  // 绿灯
  if (val === 3) {
    return '#52C41B'
  }
}

/**
 * 获取图表数据中孤立的点
 * @param list
 * @returns {*[]}
 */
export const getIsolatedPointList = (list) => {
  if (isEmpty(list)) {
    return []
  }
  const pointList = []
  list.forEach((v, currentIndex) => {
    // 判断是孤点
    if (currentIndex === 0) {
      if (list.length > 1 && (isEmpty(list[1]) || +list[1] === 0)) {
        pointList.push(currentIndex)
      }
    } else if (currentIndex === list.length - 1) {
      if (isEmpty(list[list.length - 2]) || +list[list.length - 2] === 0) {
        pointList.push(currentIndex)
      }
    } else {
      const prevIndex = currentIndex - 1
      const afterIndex = currentIndex + 1
      if ((isEmpty(list[prevIndex]) || +list[prevIndex] === 0) && (isEmpty(list[afterIndex]) || +list[afterIndex] === 0)) {
        pointList.push(currentIndex)
      }
    }
  })
  return pointList
}

export const getUUID = () => {
  return uuidV4()
}

export const replaceDemo = (value) => {
  const userStore = useUserStore()
  if (!value) {
    return '--'
  }
  if (userStore.isDemo) {
    return value.replace(/Tineco/g, 'Demo')
  }
  return value
}

export function findPath(tree, id, mapKeys) {
  const { children, subTags, ...rest } = tree
  if (tree[mapKeys[1]] === id) {
    return [rest]
  }
  for (const child of (tree[mapKeys[2]] ?? [])) {
    const path = findPath(child, id, mapKeys)
    if (path.length > 0) {
      return [rest, ...path]
    }
  }
  return []
}

/**
 * 前端排序，--排在0前面
 * @param {*} a
 * @param {*} b
 * @param {*} key
 * @returns
 */
export function sortNum(a, b, key) {
  if (a[key] === null) {
    return -1
  }
  if (b[key] === null) {
    return 1
  }
  return a[key] - b[key]
}

/**
 * 生成自动化测试属性dataCy的值
 * @param componentIndex 页面组件索引
 * @param index 组件内部索引
 * @param autoTestPrefix 获取不到route的情况下主动传值
 * @returns {string|null}
 */
export function genDataCy(componentIndex, index, autoTestPrefix = null) {
  const route = useRoute()
  if (isEmpty(route?.meta?.autoTestPrefix) && isEmpty(autoTestPrefix)) {
    return null
  } else {
    return `${route?.meta?.autoTestPrefix ?? autoTestPrefix}${componentIndex.toString().padStart(2, '0')}${index.toString().padStart(2, '0')}`
  }
}

/**
 * filter添加dataCy
 * @param filter filter集合
 * @param componentIndex 页面组件索引
 * @param autoTestPrefix 获取不到route的情况下主动传值
 * @returns {*}
 */
export function appendDataCy(filter, componentIndex, autoTestPrefix = null) {
  filter.forEach((v, i) => {
    const dataCy = genDataCy(componentIndex, i, autoTestPrefix)
    if (v.type.includes('select')) {
      v.dataCyValue = dataCy
      v.dataCy = `${dataCy}_dropList`
      v.dataCyFilter = `${dataCy}_filter`
    } else {
      v.dataCy = dataCy
    }
  })
  return filter
}
/**
 * 获取nonType参数
 * nonType 1代表non brand 2代表non category 3代表全部 null代表无条件
 * @param params
 * @returns {number|null}
 */
export function getNonType(params) {
  const b = params.customBrand?.findIndex(v => v.id === -1) >= 0
  const c = params.customCategory?.findIndex(v => v.id === -1) >= 0
  return b && c ? 3 : b ? 1 : c ? 2 : null
}
/**
 * 10级brandCategory转换
 * @param data 原始对象
 * @param type brand/category
 * @param isId 是否是Id字段
 * @param newValue 需要写入原始对象的新值
 * @returns {*[]}
 */
export function convertTenBrandCategory(data = {}, type = 'brand', isId = true, newValue = []) {
  const indexKeys = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'] // 后期根据需求添加
  const suffix = isId ? 'Id' : ''
  if (isEmpty(newValue)) {
    return indexKeys.map(v => data[`${type}Level${v}${suffix}`]?.toString()).filter(v => !isEmpty(v))
  } else {
    indexKeys.forEach((v, i) => {
      data[`${type}Level${v}${suffix}`] = newValue[i] ?? ''
    })
    return data
  }
}

/**
 * 10级Table数据插入对于节点
 * @param {*} data tableData
 * @param {*} response 接口返回child
 * @param {*} row 当前行数据
 * @param {*} key
 * @returns
 */
export function insertIntoChildren(data, response, row, key = 'brandId') {
  // 递归插入数据 根据key值找到对应的节点
  if (data.length === 0) { return }
  for (let i = 0; i < data.length; i++) {
    if (data[i][key] === row[key] && data[i].groupKeyStartDate === row.groupKeyStartDate) {
      data[i].children = response
      return
    }
    if (data[i].children) {
      insertIntoChildren(data[i].children, response, row, key)
    }
  }
}

/**
 * HEX 加透明度
 *  @param {*} bgColor
 *  @param {*} alpha
 */
export function hexToRgba(bgColor, alpha) {
  const color = bgColor.slice(1) // 去掉'#'号
  const rgba = [
    parseInt(color.slice(0, 2), 16),
    parseInt(color.slice(2, 4), 16),
    parseInt(color.slice(4, 6), 16),
    alpha
  ]
  return `rgba(${rgba.join()})`
}

/**
 * 处理TIFF文件二进制数据
 * @param {ArrayBuffer} buffer
 * @returns Object
 */
async function processTiff(buffer) {
  const UTIF = await import('utif')
  const ifds = UTIF.decode(buffer)
  UTIF.decodeImage(buffer, ifds[0])
  const imgWidth = ifds[0].width
  const imgHeight = ifds[0].height
  const rgba = UTIF.toRGBA8(ifds[0])
  const canvas = document.createElement('canvas')
  canvas.width = imgWidth
  canvas.height = imgHeight
  const ctx = canvas.getContext('2d')
  const imageData = ctx.createImageData(canvas.width, canvas.height)
  imageData.data.set(new Uint8ClampedArray(rgba))
  ctx.putImageData(imageData, 0, 0)
  return {
    base64Url: canvas.toDataURL(),
    width: imgWidth,
    height: imgHeight
  }
}

/**
 * TIFF文件转base64
 * @param {File} file
 * @returns Object
 */
export function tiffFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.readAsArrayBuffer(file)
    fr.onload = async function(e) {
      try {
        const buffer = e.target.result
        const result = await processTiff(buffer)
        resolve(result)
      } catch (err) {
        reject(err)
      }
    }
    fr.onerror = reject
  })
}

/**
 * TIFF链接转base64
 * @param {String} url 图片链接
 * @returns Object
 */
export function tiffUrlToBase64(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'arraybuffer'
    xhr.open('GET', url)
    xhr.onload = async function(e) {
      try {
        const buffer = e.target.response
        const result = await processTiff(buffer)
        resolve(result)
      } catch (err) {
        reject(err)
      }
    }
    xhr.onerror = reject
    xhr.send()
  })
}


/**
 * 找到数组中的孤立点
 *  @param {*} arr
 *  @returns Array {index, value}
 */
export function findIsolatedPoints(arr) {
  const isolatedPoints = []
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== null) {
      const prev = arr[i - 1] === undefined || arr[i - 1] === null
      const next = arr[i + 1] === undefined || arr[i + 1] === null

      if (prev && next) {
        isolatedPoints.push({ index: i, value: arr[i] })
      }
    }
  }
  return isolatedPoints
}
