import type { Ref, ComponentPublicInstance } from 'vue'
import type { Step } from 'driver.js'
import Driver from 'driver.js'
import { isRef } from 'vue'
import { getEleClosest } from '@!/utils/dom'
import { OpenAsinDetailTypeDriverTipEnum } from '@!/const/AsinDetail'
import { useAsinDetailStore, PiniaPersistList } from '@!/store'
/*
  openDriver(
    tableRef: 表格实例
    tableFirstRowSelector: 表格高亮行元素选择器
    tableScrollYWrapQuery: 表格最近y轴包裹元素选择器
  )
*/

const WatchFields = [
  'isDriverInit'
]

const state = () => {
  return {
    // 引导是否初始化完成
    isDriverInit: false,
    // 引导是否打开状态中
    isDriverOpening: false
  }
}

const getters = {
  // 是否为初始状态
  isDriverInitState() {
    return !this.isDriverInit && !this.isDriverOpening
  }
}
// driver query selector
const driverHighlightedStageEleSelector = '#driver-highlighted-element-stage'
const driverPopoverClassName = 'AsinDetailDriver-first-step'

// 定时器
let driverInitTimeout:ReturnType<typeof setTimeout>
let driverDelayTimeout:ReturnType<typeof setTimeout>
// 清除定时器
const clearDriverTimeout = (timeOut:ReturnType<typeof setTimeout>) => {
  if (timeOut !== null) {
    clearTimeout(timeOut)
    timeOut = null
  }
}

const actions = {
  // 缓存写入方法
  setIsDriverInit(val:boolean):void {
    this.isDriverInit = val
  },
  // 打开引导
  openDriver(tableRef:Ref< ComponentPublicInstance > | HTMLElement, tableFirstRowSelector = '.surely-table-row-level-0', tableScrollYWrapQuery = '.el-scrollbar__wrap--hidden-default'):void {
    // 初始化完成
    if (this.isDriverInit) { return }
    // 打开状态
    if (this.isDriverOpening) { return }
    let tableRefEle: HTMLElement
    if (isRef(tableRef)) {
      tableRefEle = tableRef.value?.$el
    } else {
      tableRefEle = tableRef
    }
    // tableRef 不存在
    if (!tableRefEle) {
      console.log('TableRefEle is not be null')
      return
    }
    const tableFirstRowEle:HTMLElement | null = tableRefEle.querySelector(tableFirstRowSelector)
    if (!tableFirstRowEle) {
      console.warn('Not find the table first row Element')
      return
    }
    // table -> scrollXEle
    const tableChildOfScrollEle:HTMLElement = tableRefEle.querySelector('.header-scroll-body')

    // table -> scrollYWrapEle
    let tableScrollYWrapEle:HTMLElement | void
    let tableScrollYWrapEleByOverflow:string
    let hiddenDelayTime = 50
    if (tableScrollYWrapQuery) {
      tableScrollYWrapEle = getEleClosest(tableRefEle, tableScrollYWrapQuery)
      if (tableScrollYWrapEle) {
        tableScrollYWrapEleByOverflow = tableScrollYWrapEle.style.overflowY
        tableScrollYWrapEle.style.overflowY = 'hidden'
        hiddenDelayTime = 300
      }
    }
    // 根据 AsinDetail 打开方式 显示对应提示文本
    const asinDetailStore = useAsinDetailStore()
    const tipText = OpenAsinDetailTypeDriverTipEnum[ asinDetailStore.openAsinDetailType ]
    const driverSteps:Step = {
      element: tableFirstRowEle, // Query selector string or Node to be highlighted
      popover: { // There will be no popover if empty or not given
        className: driverPopoverClassName, // className to wrap this specific step popover in addition to the general className in Driver options
        title: ' ',
        description: `<div style='display:flex;align-items:center'>
                        <span style='font-size: 14px;font-weight: 400;text-align: left;color: var(--color-title--);line-height: 1.5;'>${tipText}</span>
                      </div>`,
        showButtons: true, // Do not show control buttons in footer
        closeBtnText: 'OK', // Text on the close button for this step
        position: 'top'
      }
    }

    const handleResetOverflowY = () => {
      if (tableScrollYWrapEle) {
        tableScrollYWrapEle.style.overflowY = tableScrollYWrapEleByOverflow
      }
    }
    clearDriverTimeout(driverInitTimeout)
    driverInitTimeout = setTimeout(() => {
      const driverIntance = new Driver({
        padding: 0,
        allowClose: false,
        keyboardControl: false,
        onDeselected: () => {
          // this.isDriverInit = true
          this.setIsDriverInit(true)
          this.isDriverOpening = false
          setTimeout(() => {
            if (tableScrollYWrapEle) {
              tableScrollYWrapEle.style.overflowY = tableScrollYWrapEleByOverflow
            }
            document.querySelector('.' + driverPopoverClassName).remove()
          })
        }
      })
      try {
        // 元素被移除掉了
        if (!tableFirstRowEle) {
          return
        }
        const tableFirstRowEleRect = tableFirstRowEle.getBoundingClientRect()
        // 不在可视区域 ( 被父元素隐藏  )
        if (tableFirstRowEleRect.width === 0 &&
            tableFirstRowEleRect.height === 0
        ) {
          handleResetOverflowY()
          console.log('Driver init element is not in rect view')
          return
        }
        // 设置高亮层遮罩蒙层
        driverIntance.highlight(driverSteps)
        this.isDriverOpening = true
        clearDriverTimeout(driverDelayTimeout)
        driverDelayTimeout = setTimeout(() => {
          const highlightedStageEle = document.querySelector(driverHighlightedStageEleSelector)
          const firstStepEle = document.querySelector('.' + driverPopoverClassName)
          if (highlightedStageEle) {
            highlightedStageEle.setAttribute('style', (highlightedStageEle.getAttribute('style') || '') + ` transform: translateX(${tableChildOfScrollEle.scrollLeft}px);width:${tableRefEle.clientWidth}px;`)
          }
          if (firstStepEle) {
            firstStepEle.setAttribute('style', (firstStepEle.getAttribute('style') || '') + `  transform: translateX(${tableChildOfScrollEle.scrollLeft}px );`)
          }
          if (!highlightedStageEle && !firstStepEle) {
            console.log('Driver init failed')
            this.isDriverOpening = false
            handleResetOverflowY()
          }
        }, 50)
      } catch (err) {
        console.log('Driver init Error: ', err)
        this.isDriverOpening = false
        handleResetOverflowY()
      }
    }, hiddenDelayTime)
  },
  // 初始化driver
  resetDriver():void {
    if (this.isDriverOpening) {
      return
    }
  },
  // 获取 table column fixed属性
  getColumnFixed<T extends 'left' | 'right'>(fixed:T):T|void {
    if (this.isDriverInitState) {
      return null
    }
    if (this.isDriverOpening) {
      return null
    }
    return fixed
  },
  // 设置 table column fixed属性
  setColumnFixed<T extends 'left' | 'right', U extends {fixed?: string | void }>(fixed:T, column?:U):T|void {
    const columnFixed = this.getColumnFixed(fixed)
    if (column) {
      column.fixed = columnFixed
    }
    return columnFixed
  },
  setUserPersist() {
    const userid = localStorage.getItem('uid')
    WatchFields.forEach((fieldName) => {
      const storageKey = PiniaPersistList.includes(fieldName) ? 'storage_' : ''
      const storeKey = `${storageKey}${userid}_${fieldName}`
      const storeStr = localStorage.getItem(storeKey)
      if (storeStr) {
        this[fieldName] = JSON.parse(storeStr)
      }
    })
  }
}

export default {
  state,
  getters,
  actions,
  persist: {
    enabled: true,
    isCommon: true,
    watchFields: WatchFields,
    isLogoutClearStorage: true,
    logoutWatchFields: ['isDriverInit']
  }
}
