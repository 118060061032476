import { createPinia } from 'pinia'
import { userStorePersistPlugin } from '@commerceCommon/utils/userStorePersistPlugin'
import { createUseRouterStore } from '@commerceCommon/utils/useStoreForm'
import * as storeModuleMap from './modules/index'
export * from './modules/index'
export const PiniaPersistList = [
  'commerceType',
  'marketStoreValueMap',
  'isDriverInit',
  'isAlertDriver',
  'salePanelMap',
  'realTimeSalePanelMap',
  'vendorCodeValue',
  // from frameStore
  'dateRange',
  'chooseAccounts',
  'compareTime',
  'compareSummary',
  'isCompare',
  'productPermission',
  'channelType',
  'isCompareCustom',
  'isComparePop',
  'isCompareYoy',
  'latestDateMode'
]
export default function createCustomPinia() {
  const pinia = createPinia()
  pinia.use(
    userStorePersistPlugin({
      PiniaPersistList
    })
  )
  return pinia
}

export const useRouterStore = createUseRouterStore({
  storeModuleMap: storeModuleMap
})

